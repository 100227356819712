import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState, useCallback } from "react";
import useRazorpay from "react-razorpay";
import { toast } from 'react-toastify';
import { getCart, razorpayOrder } from "../../redux/actions/product";
import { fetchAddress, fetchStartPoint, getMapDistance } from "../../redux/actions/home";
import { initiateOrder } from "../../redux/actions/product";
import HeaderTab from "./components/HeaderTab";
import Header from '../../components/Header';
import Basket from "./components/Basket";
import Delivery from "./components/Delivery";
import Payment from "./components/Payment";
import ShareDetails from './components/ShareDetails';
import CheckoutPriceDetails from './components/CheckoutPriceDetails';
import { useHistory } from 'react-router-dom';



const Cart = () => {
   let history = useHistory();

   let breadCrumbTabs = ["My Basket", "Delivery Details", "Payment Details"]
   const { cartList, orderDetails, orderPlaced ,razorpayOrderStatus} = useSelector((state) => state.product);
   const { addressList: { addresses } } = useSelector((state) => state.home);
   const { startPoint, distanceDetails, shippingCharges } = useSelector((state) => state.home);
   const [checkoutTab, setCheckoutTab] = useState("My Basket")
   const [isSelfPickup, setSelfPickup] = useState(1)
   const [amountTobePaid, setAmountTobePaid] = useState(0)
   const [shippingCharge, setShippingCharge] = useState(0)
   const [grandTotal, setGrandTotal] = useState(0)
   const [isMinimumSelected, setMinimumSelected] = useState("0")
   const [activeTab, setActiveTab] = useState(breadCrumbTabs[0]);
   const dispatch = useDispatch()
   const Razorpay = useRazorpay();
   const [defaultAddress, setDefaultAddress] = useState(localStorage.getItem("defaultAddressId") ? localStorage.getItem("defaultAddressId") : "")
   const handleStoreDefaultStore = (id) => {
      localStorage.setItem("defaultAddressId", id)
      setDefaultAddress(id)
   }

   useEffect(() => {
      dispatch(getCart())
      dispatch(fetchAddress())
      dispatch(fetchStartPoint())
   }, [])
   useEffect(() => {
      setGrandTotal(cartValue)
      setAmountTobePaid(cartValue * 25 / 100)
   }, [cartList])

   useEffect(() => {
      if (shippingCharges) {
         setShippingCharge(parseFloat(shippingCharges.deliveryCharge))
         setGrandTotal(cartValue + parseFloat(shippingCharges.deliveryCharge))
         setAmountTobePaid((cartValue + parseFloat(shippingCharges.deliveryCharge)) * 25 / 100)
      }

   }, [shippingCharges])

   useEffect(() => {
      let destinationPoint = {}
      destinationPoint = addresses?.find((address) =>
         address.id == localStorage.getItem("defaultAddressId")
      )

      if (isSelfPickup == 0)
         dispatch(getMapDistance(startPoint, destinationPoint, isSelfPickup))
      else {
         setShippingCharge(0)
         setGrandTotal(cartValue)
         setAmountTobePaid(cartValue * 25 / 100)
      }

   }, [addresses, startPoint, isSelfPickup, defaultAddress])

   const placeOrder = () => {
      let orderData = {
         amount: amountTobePaid,
         distance: distanceDetails,
         addressId: defaultAddress,
         isSelfPickUp: isSelfPickup
      }
      dispatch(initiateOrder(orderData))
      if (orderPlaced){
      openRazorPay()
      }
   }

   const openRazorPay = () => {
      let phoneNumber = JSON.parse(localStorage.getItem("userAuthData")).phoneNumber;
      const options = {

         key: "rzp_live_zgJHOk27NWaqaw", // Enter the Key ID generated from the Dashboard
         amount: amountTobePaid * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
         currency: "INR",
         name: "My WareHouse",
         description: "My Warehouse Online Payment",
         //   image: "https://example.com/your_logo",
         order_id: orderDetails?.paymentDetails?.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
         handler: async function (response) {
            if(!response) return;
            const {razorpay_payment_id,razorpay_order_id,razorpay_signature}=response;
            const razorpayData={razorpay_payment_id,razorpay_order_id,razorpay_signature}
            dispatch(razorpayOrder(razorpayData));
               dispatch(getCart());
               toast.success("Order placed successfully");
               history.push('/my-orders');
         },
         prefill: {
            contact: phoneNumber,
         },
         notes: {
            address: "Razorpay Corporate Office",
         },
         theme: {
            color: "#3399cc",
         },
      };
      const rzp1 = new Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", function (response) {
         // alert(response.error.code);
         // alert(response.error.description);
         toast.error(response.error.description);
      });
      
     
   };


   const handleChangeCheckout = () => {
      if (checkoutTab == "My Basket") {
         setCheckoutTab("Delivery Details")
         setActiveTab('Delivery Details')
      }

      if (checkoutTab == "Delivery Details") {
         setCheckoutTab("Payment Details")
         setActiveTab('Payment Details')
      }
      if (checkoutTab == "Payment Details") {
         if (amountTobePaid < cartValue * 25 / 100)
            toast.error("Should pay minimum 25% of Cart; Rs." + cartValue * 25 / 100);
         else if (amountTobePaid > cartValue)
            toast.error("Amount exceeds Cart Amount");
         else
            placeOrder()
      }

   }
   const handlePayMinimum = (value) => {
      setAmountTobePaid((cartValue + shippingCharge) * 25 / 100)
      setMinimumSelected(value)
   }
   const handlePayOther = (value) => {
      setAmountTobePaid("")
      setMinimumSelected(value)
   }

   var cartValue = 0
   cartList && cartList.items && cartList.items.length > 0 && cartList.items.map((item) => {
      cartValue = cartValue + (item.price * item.units)
   })
   return (
      <>
         <div className="content">
            <div className="order-page">
               <HeaderTab tabs={breadCrumbTabs} setCheckoutTab={setCheckoutTab} checkoutTab={checkoutTab} activeTab={activeTab} setActiveTab={setActiveTab} />
               <hr />


               <div class="row justify-content-center">
                  {checkoutTab == "My Basket" ?
                     <div className="col-md-9 my-basket-wrap"><Basket cartList={cartList} /></div> : null}
                  {checkoutTab == "Delivery Details" ?
                     <div className="col-md-9 delivery-details-wrap">
                        <Delivery addresses={addresses} setSelfPickup={setSelfPickup} isSelfPickup={isSelfPickup}
                           handleStoreDefaultStore={handleStoreDefaultStore} defaultAddress={defaultAddress} />
                     </div> : null}
                  {checkoutTab == "Payment Details" ?
                     <div className="col-md-9 my-basket-wrap"> <Payment handlePayMinimum={handlePayMinimum} handlePayOther={handlePayOther}
                        amountTobePaid={amountTobePaid} isMinimumSelected={isMinimumSelected} setAmountTobePaid={setAmountTobePaid}
                        cartValue={cartValue} />
                     </div> : null}

                  <div class="col-md-3 order-details-wrap">
                     <CheckoutPriceDetails cartValue={cartValue} handleChangeCheckout={handleChangeCheckout} grandTotal={grandTotal} shippingCharge={shippingCharge} />
                     <ShareDetails />

                  </div>

               </div>
            </div>

         </div>
      </>
   )
}
export default Cart;
