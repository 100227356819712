import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import FilterBox from "../MyOrders/FilterBox";
import PurchaseCard from "../../components/PurchaseCard";
import InvoiceDetails from "../../components/InvoiceDetails";
import { useDispatch, useSelector } from 'react-redux'

import { getOrderList } from "../../redux/actions/product"


const PurchaseHistory = () => {
  let breadCrumbTabs = ["Purchase History"];
  const [selectedInvoiceData, setSelectedInvoiceData] = useState({})
  const { orderList } = useSelector((state) => state.product);

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOrderList(1, 0))
  }, [])
  useEffect(() => {
    if (orderList?.orders && orderList.orders.length > 0)
      setSelectedInvoiceData(orderList.orders[0])
  }, [orderList])
  const handleChangeInvoiceCard = (order) => {
    setSelectedInvoiceData(order)
  }

  const handleFilterOrder = (type) => {
    dispatch(getOrderList(1, type))
  }
  return (
    <div class="purchase-history">
      <div className="left">
        <Breadcrumbs tabs={breadCrumbTabs} />
        <FilterBox handleFilterOrder={handleFilterOrder} />
        <div class="purchase-cards-wrap">
          <PurchaseCard data={orderList?.orders} handleChangeInvoiceCard={handleChangeInvoiceCard} />
        </div>
      </div>
    </div>
  )
}
export default PurchaseHistory