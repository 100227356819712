import React, { useState } from "react";
const FilterBox = ({ handleFilterOrder }) => {
    const [active, setActive] = useState("")
    const filterOrder = (type) => {
        handleFilterOrder(type)
        setActive(type)
    }

    return (
        <div className="filter-box d-flex">
            <a onClick={() => filterOrder("")} className={`filter ${active == "" && 'active'}`}>ALL</a>
            <a onClick={() => filterOrder("placed")} className={`filter ${active == "placed" && 'active'}`}>PLACED</a>
            <a onClick={() => filterOrder("pending")} className={`filter ${active == "pending" && 'active'}`}>PENDING</a>
            <a onClick={() => filterOrder("packed")} className={`filter ${active == "packed" && 'active'}`}>PACKED</a>
            <a onClick={() => filterOrder("dispatched")} className={`filter ${active == "dispatched" && 'active'}`}>DISPATCHED</a>
            <a onClick={() => filterOrder("in_transit")} className={`filter ${active == "in_transit"&& 'active'}`}>IN TRANSIST</a>
            <a onClick={() => filterOrder("delivered")} className={`filter ${active == "delivered" && 'active'}`}>DELIVERED</a>
            <a onClick={() => filterOrder("completed")} className={`filter ${active == "completed" && 'active'}`}>COMPLETED</a>
            <a onClick={() => filterOrder("cancelled")} className={`filter ${active == "cancelled" && 'active'}`}>CANCELLED</a>
            <a onClick={() => filterOrder("uploaded")} className={`filter ${active == "uploaded" && 'active'}`}>UPLOADED</a>
            <a onClick={() => filterOrder("approval_pending")} className={`filter ${active == "approval_pending"&& 'active'}`}>APPROVAL PENDING</a>
            <a onClick={() => filterOrder("accepted")} className={`filter ${active == "accepted" && 'active'}`}>ACCEPTED</a>
            <a onClick={() => filterOrder("rejected")} className={`filter ${active == "rejected" && 'active'}`}>REJECTED</a>
            <a onClick={() => filterOrder("payment_failed")} className={`filter ${active == "payment_failed" && 'active'}`}>FAILED PAYMENT</a>
        </div>
    )
}
export default FilterBox