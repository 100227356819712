const CheckoutPriceDetails = ({handleChangeCheckout,cartValue,grandTotal,shippingCharge}) =>{
    return(
        <div class="order-details">
                    <div class="row detail">
                        <div class="col">Cart Value</div>
                        <div class="col">₹ {cartValue}</div>
                    </div>
                    <div class="row detail">
                        <div class="col">Shipping Charge</div>
                        <div class="col">₹ {shippingCharge}</div>
                    </div>
                    <div class="row detail">
                        <div class="col">Minimum Amount</div>
                        <div class="col">₹ {(cartValue+shippingCharge) * 25/100}</div>
                    </div>
                    <div class="row grand-total">
                        GRAND TOTAL <span>₹ {grandTotal}</span>
                    </div>
                    <div class="row">
                        <a class="btn" onClick={handleChangeCheckout}>Check Out <img src="assets/icons/arrow-right.svg"
                                alt=""/></a>
                    </div>
                </div>
    )
}
export default CheckoutPriceDetails