import React, { useEffect, useCallback } from "react";
import ProfileGridSection from "../../components/ProfileGridSection";
import HomeBanner from "../../components/Banners/HomeBanner";
import CategoriesGrid from "../../components/CategoriesGrid";
import HotLooks from "../../components/HotLooks";
import OfferBanner from "../../components/Banners/OfferBanner";
import BottomBanner from "../../components/Banners/BottomBanner";
import Offer from "../../components/Banners/Offer";
import ProductBanner from "../../components/Banners/ProductBanner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchBanner,
  fetchCategories,
  fetchPopularCategories,
  fetchHotLooks,
  fetchOffers,
  fetchPopularNdNewProducts,
  fetchSubCategories,
} from "../../redux/actions/home";
import { getUserData } from "../../redux/actions/login";
import { getUserToken } from "../../logics/User/userData";
import { useHome } from "../../logics/Home/useHome";
import SubCategory from "../../components/SubCategory";

const Home = () => {
  const {
    homeTopBannerData,
    homeBannerData,
    offerTopBannerData,
    categoriesData,
    popularCategoriesData,
    subCategoryData,
    hotLooksData,
    productBannerData,
    bottomBannerData,
    nonTileOffers,
    tileOffers,
    popularProductsData,
    newArrivalProductsData,
  } = useSelector((state) => state.home);
  const { signupOtpResp, loginOtpResp } = useSelector((state) => state.login);
  const { userData } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const history = useHistory();
  const logicProps = useHome();
  const { slideRight, slideLeft } = logicProps;

  useEffect(() => {
    dispatch(fetchBanner("home_screen_top"));
    dispatch(fetchBanner("home"));
    dispatch(fetchBanner("home_screen_offer_top"));
    dispatch(fetchBanner("subcategory"));
    dispatch(fetchBanner("web_banner_1"));
    dispatch(fetchBanner("web_banner_2"));
    dispatch(fetchCategories());
    dispatch(fetchSubCategories(3));
    dispatch(fetchPopularCategories());
    dispatch(fetchHotLooks());
    dispatch(fetchOffers());
    dispatch(fetchPopularNdNewProducts());
    if (getUserToken()) {
      dispatch(getUserData());
    }
  }, [signupOtpResp, loginOtpResp]);
  const selectSubCategoryClick = useCallback((sub_category) => {
    // setShowNotificationDrawer(!showNotificationDrawer)
    let path = `/product-listing/${"Tile Fixing Solution"}+${
      sub_category.parentId
    }/${sub_category.displayName}+${sub_category.id}`;
    // setShowMenuDrawer(false)
    history.push(path);
  }, []);
  const selectPopularCategoryClick = useCallback((sub_category) => {
    // setShowNotificationDrawer(!showNotificationDrawer)
    let path = `/product-listing/${"Popular"}+${sub_category.parentId}/${
      sub_category.displayName
    }+${sub_category.id}`;
    // setShowMenuDrawer(false)
    history.push(path);
  }, []);
  return (
    <>
      <div className="content">
        <ProfileGridSection
          bannerList={homeTopBannerData}
          userData={userData}
        />{" "}
        {/*userData and TopBanner */}
        <HomeBanner bannerList={homeBannerData} />
        <CategoriesGrid
          CategotyList={categoriesData}
          title="Categories"
          type="See all"
        />
        <ProductBanner bannerList={productBannerData} />
        <OfferBanner offerBannerData={offerTopBannerData} />
        <Offer offers={tileOffers} type="tile" />
        <HotLooks hotLookList={hotLooksData} />
        <Offer offers={nonTileOffers} type="nonTile" />
        <BottomBanner bannerList={bottomBannerData} />
        <HotLooks
          hotLookList={newArrivalProductsData}
          title="New Arrivals"
          tag="New"
        />
        <HotLooks
          hotLookList={popularProductsData}
          title="Popular Products"
          tag="Popular"
        />
        <div class="categories-wrap no-padding">
          <SubCategory
            selectSubCategoryClick={selectSubCategoryClick}
            subCategoryData={subCategoryData}
            slideLeft={slideLeft}
            slideRight={slideRight}
            title="Tile Fixing Solution"
          />
          <SubCategory
            selectSubCategoryClick={selectSubCategoryClick}
            subCategoryData={popularCategoriesData}
            title="Popular Categories"

            slideLeft={slideLeft}
            slideRight={slideRight}
          />
        </div>
      </div>
    </>
  );
};
export default Home;
