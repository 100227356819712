import React from "react";
import Grids from "./Grids";

const HotLooks = ({hotLookList,title="Hot looks", tag="Hot looks"}) =>{
 return(
    <section className="row mb-0">
    <div className="section-header">
        <div className="section-title">{title}</div>
    </div>
    <Grids hotLookList={hotLookList} tag={tag} />
</section>
 )
}
export default HotLooks;