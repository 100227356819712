import { GET_WISH_LIST,ADD_ITEM_TO_WISHLIST,REMOVE_ITEM_FROM_WISHLIST,REFRESH_WISHLIST_STATUS } from './types';
import tileEcomapi from '../../apis';

export const getWishlist = () => dispatch => {
    tileEcomapi.get('wishlist/').then((resp) => {
       
        if (resp.data.statusCode == 200)
            dispatch({
                type: GET_WISH_LIST,
                payload: resp.data.response
            })
    }).catch((error) => {
       console.log(error)
    })
}
export const addItemtoWishlist = (data) => dispatch => {
    tileEcomapi.post('wishlist/',data).then((resp) => {
        
        if (resp.data.statusCode == 200)
            dispatch({
                type: ADD_ITEM_TO_WISHLIST,
                payload: resp.data.status
            })
    }).catch((error) => {
       console.log(error)
    })
}
export const removeItemFromWishlist = (data) => dispatch => {
    tileEcomapi.delete('wishlist/',{data}).then((resp) => {
       
        if (resp.data.statusCode == 200)
            dispatch({
                type: REMOVE_ITEM_FROM_WISHLIST,
                payload: resp.data.status
            })
    }).catch((error) => {
       console.log(error)
    })
}
export const refreshWishlistStatus = () => dispatch => {
            dispatch({
                type: REFRESH_WISHLIST_STATUS,
            })
}