import React from "react";
import '../../pages/ProductListing/ProductListing.css'
import BreadcrumbsIcon from "../../assets/icons/breadcrumbs-icon.svg"
import {useBreadCrumbs} from "../../logics/BreadCrumbs/useBreadCrumbs"
import { useLocation } from "react-router-dom";
const Breadcrumbs = ({tabs}) =>{ 
    const logicProps = useBreadCrumbs({tabs});
    const {
        activeTab,
        selectActiveItem,
        redirectToMyaddress
      } = logicProps;
      const location = useLocation();
    return(
<div className="breadcrumbs align-items-start">
            <img className="icon" src={BreadcrumbsIcon} alt="" />
            { tabs && tabs.length && tabs.length>0 ?
            tabs.map((item,index) =>(
                <a key={index} 
                className={`${tabs.length == 3 ?  index ==2 ? `link active`:`link`: activeTab ==item ? `link active`: `link`}`} 
                onClick={tabs.length == 3 ? ()=>selectActiveItem(item):location.pathname == "/add-address" && index ==0 ? redirectToMyaddress:null}>{item}</a>
            )

            ):null}
            {/* <a className="link">Categories</a>
            <a className="link">Floor Tiles</a>
            <a className="link">Living Room</a> */}
            {tabs[0] == "Wishlist" ?<span className="count"></span>:null}
        </div>
    )

}
export default Breadcrumbs