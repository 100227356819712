import React from "react";
import { Link } from 'react-router-dom';
// import profileIcon from '../../assets/images/profile-image.jpg'
const ProductCategoriesList = ({openProductSubCategories,categories,selectedCategory}) =>{
   
    return(
        <div className="sublink-wrap">
        {categories && categories.length>0 && categories.map((category)=>(
            <a className={`sublink ${selectedCategory == category.displayName ? "active":"" }` } onClick={()=>openProductSubCategories(category.id,category.displayName)}>{category.displayName}</a>
        ))}
        {/* <a href="#" className="sublink" onClick={openProductSubCategories}>Floor Tiles</a>
        <a href="#" className="sublink">Wall Tiles</a>
        <a href="#" className="sublink">Granites</a>
        <a href="#" className="sublink">Sanitary Fittings</a>
        <a href="#" className="sublink">CP Fittings</a>
        <a href="#" className="sublink">Bathroom Accessories</a>
        <a href="#" className="sublink">Furniture</a>
        <a href="#" className="sublink">Household Items</a>
        <a href="#" className="sublink">Kitchen Sink</a>
        <a href="#" className="sublink">Adhesive and Epoxy</a> */}
    </div>
    )
}
export default ProductCategoriesList