import {
  FETCH_HOME_TOP_BANNER,
  FETCH_HOME_BANNER,
  FETCH_OFFER_BANNER,
  FETCH_OFFERS,
  FETCH_NON_TILE_OFFERS,
  FETCH_TILE_OFFERS,
  FETCH_CATEGORIES,
  FETCH_POPULAR_CATEGORIES,
  FETCH_SUB_CATEGORIES,
  FETCH_HOTLOOKS,
  SELECT_CATEGORY_ID,
  FETCH_NOTIFICATIONS,
  FETCH_ADDRESS,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  FETCH_START_POINT,
  GET_DISTANCE,
  CALCULATE_SHIPPING_CHARGE,
  UPLOAD_ORDER,
  FETCH_POPULAR_PRODUCTS,
  FETCH_NEW_ARRIVAL_PRODUCTS,
  FETCH_PRODUCT_BANNER,
  FETCH_BOTTOM_BANNER
} from "./types";
import tileEcomapi from "../../apis";
import axios from "axios";
import { toast } from "react-toastify";
import { getDistance } from "geolib";

export const fetchBanner = (data) => (dispatch) => {
  tileEcomapi
    .get(`banners?screen=${data}`)
    .then((resp) => {
      switch (data) {
        case "home_screen_top":
          dispatch({
            type: FETCH_HOME_TOP_BANNER,
            payload: resp.data.response,
          });
          break;
        case "home":
          dispatch({
            type: FETCH_HOME_BANNER,
            payload: resp.data.response,
          });
          break;
        case "home_screen_offer_top":
          dispatch({
            type: FETCH_OFFER_BANNER,
            payload: resp.data.response,
          });
          break;
        case "web_banner_1":
          dispatch({
            type: FETCH_PRODUCT_BANNER,
            payload: resp.data.response,
          });
          break;
        case "web_banner_2":
          dispatch({
            type: FETCH_BOTTOM_BANNER,
            payload: resp.data.response,
          });
          break;

        default:
          break;
      }
    })
    .catch((error) => {});
};

export const fetchCategories = () => (dispatch) => {
  tileEcomapi
    .get("categories/")
    .then((resp) => {
      dispatch({
        type: FETCH_CATEGORIES,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};
export const fetchPopularCategories = () => (dispatch) => {
  tileEcomapi
    .get("categories/popular")
    .then((resp) => {
      dispatch({
        type: FETCH_POPULAR_CATEGORIES,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};

export const fetchSubCategories = (id) => (dispatch) => {
  tileEcomapi
    .get("categories/?parentId=" + id)
    .then((resp) => {
      dispatch({
        type: FETCH_SUB_CATEGORIES,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};

export const fetchHotLooks = () => (dispatch) => {
  tileEcomapi
    .get("products?isHotLook=1")
    .then((resp) => {
      dispatch({
        type: FETCH_HOTLOOKS,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};
export const fetchPopularNdNewProducts = () => (dispatch) => {
  tileEcomapi
    .get("products?tag=popular")
    .then((resp) => {
      dispatch({
        type: FETCH_POPULAR_PRODUCTS,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
  tileEcomapi
    .get("products?tag=new")
    .then((resp) => {
      dispatch({
        type: FETCH_NEW_ARRIVAL_PRODUCTS,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};

export const fetchOffers = () => (dispatch) => {
  tileEcomapi
    .get("offers/list?offerType=2")
    .then((resp) => {
      dispatch({
        type: FETCH_NON_TILE_OFFERS,
        payload: resp.data.response.offers,
      });
    })
    .catch((error) => {});
  tileEcomapi
    .get("offers/list?offerType=3")
    .then((resp) => {
      dispatch({
        type: FETCH_TILE_OFFERS,
        payload: resp.data.response.offers,
      });
    })
    .catch((error) => {});
};

export const selectCategoryId = (category) => async (dispatch) => {
  dispatch({ type: SELECT_CATEGORY_ID, payload: category });
};

export const fetchNotifications = () => (dispatch) => {
  tileEcomapi
    .get("user/notifications")
    .then((resp) => {
      dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};

export const fetchAddress = () => (dispatch) => {
  tileEcomapi
    .get("user/addresses/list")
    .then((resp) => {
      dispatch({
        type: FETCH_ADDRESS,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};

export const updateAddress = (id) => (dispatch) => {
  tileEcomapi
    .put(`user/addresses${id}`)
    .then((resp) => {
      dispatch({
        type: UPDATE_ADDRESS,
        payload: resp.data.status,
      });
    })
    .catch((error) => {});
};

export const deleteAddress = (id) => (dispatch) => {
  tileEcomapi
    .delete(`user/addresses/${id}`)
    .then((resp) => {
      dispatch({
        type: DELETE_ADDRESS,
        payload: resp.data.status,
      });
      dispatch(fetchAddress());
    })
    .catch((error) => {});
};

export const addAddress = (data) => (dispatch) => {
  tileEcomapi
    .post("user/addresses", data)
    .then((resp) => {
      dispatch({
        type: ADD_ADDRESS,
        status: resp.data.status,
      });
    })
    .catch((error) => {});
};

export const fetchStartPoint = () => (dispatch) => {
  tileEcomapi
    .get("delivery/get-start-point")
    .then((resp) => {
      dispatch({
        type: FETCH_START_POINT,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};

export const getMapDistance =
  (start, destination, isSelfPickup) => (dispatch) => {
    let distanceValue = 0;
    if (start && destination) {
      let dist = getDistance(
        { latitude: start.latitude, longitude: start.longitude },
        { latitude: destination.latitude, longitude: destination.longitude }
      );
      distanceValue = dist / 1000;
    }

    let data = {
      distance: distanceValue,
      isSelfPickUp: isSelfPickup,
    };
    tileEcomapi
      .post("order/calculate-charges", data)
      .then((response) => {
        dispatch({
          type: CALCULATE_SHIPPING_CHARGE,
          payload: response.data.response,
        });
      })
      .catch((error) => {});

    dispatch({
      type: GET_DISTANCE,
      payload: distanceValue,
    });
  };

export const uploadOrder = (data) => (dispatch) => {
  dispatch({
    type: UPLOAD_ORDER,
    status: true,
  });
  let formData = new FormData();
  formData.append("file", data);
  tileEcomapi
    .post("order/upload-order", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((resp) => {
      dispatch({
        type: UPLOAD_ORDER,
        status: !resp.data.status,
      });
      if (resp.data.status) {
        toast.success(
          "Order placed successfully. Our agent will contact you shortly"
        );
      }
    })
    .catch((error) => {});
};
