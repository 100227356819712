import { GET_PRODUCT_LIST,GET_SIZES_LIST } from "../actions/types.js";

const INTIAL_STATE = {
    productList: {},
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.nextPageToken ? state.productList.concat(action.payload.products):action.payload.products,
        nextPageToken:action.payload.nextPageToken
      };
      case GET_SIZES_LIST:
      return {
        ...state,
        sizeList: action.payload
      };

    default:
      return state;
  }
};
