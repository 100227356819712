import React, { useEffect } from "react";

const SizesWrap = (data) => {
    const { product } = data;
  
    return(
        <div className="size-wrap">
        <div className="title">Available Sizes</div>
        <div className="d-flex overflow-auto">
            <a className="size active">{product?.size}</a>
        </div>
    </div>
    )
}
export default SizesWrap