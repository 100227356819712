import { React, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Login from "./Login";
import LoginCode from "./LoginCode";
import SignUp from "./SignUp";
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

const Auth = ({ show, closeSigninModal }) => {
    const [userData, setUserData] = useState(null);
    const { signupOtpResp, loginResp, isSignUpSuccess, loginOtpResp } = useSelector((state) => state.login);

    useEffect(() => {
        if (loginOtpResp?.status) {
            closeSigninModal();
        }
    }, [loginOtpResp]);

    return (
        <>
            {show && <div>
                <div class="background"></div>
                <div class="login-wrap"></div>
                <Modal show={show} onHide={closeSigninModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

                    {(!isSignUpSuccess && isEmpty(loginResp)) ?
                        <Login setUserData={setUserData} /> : <LoginCode loginResp={loginResp} userData={userData} />}
                    {/* <LoginCode></LoginCode> */}
                    {isSignUpSuccess && signupOtpResp?.status ? <SignUp /> : null}
                </Modal>
            </div>}
        </>
    );
}
export default Auth;
