import { SIGNUP_USER, SIGNUP_USER_OTP_VERIFY,LOGIN_USER,LOGIN_USER_OTP_VERIFY,FETCH_USER_DATA,RESET_USER, GET_CAPTCHA, VERIFY_CAPTCHA } from "../actions/types.js";

const INTIAL_STATE = {
  isSignUpSuccess: false,
  statusCode:'',
  signUpResp: {},
  signupOtpResp:{},
  isOtpValid:false,
  isLoginSuccess:false,
  loginOtpResp:{},
  loginResp:{},
  captchaResp:{},
  isValidCaptcha:'false',
  userData:{}
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER:
      return {
        ...state,
        isSignUpSuccess: action.status,
        statusCode:action.statusCode,
        signUpResp: action.signUpResp
      };

    case SIGNUP_USER_OTP_VERIFY:
      return {
        ...state,
        signupOtpResp: action.otpResp,
      };
      case LOGIN_USER_OTP_VERIFY:
        return {
          ...state,
          isLoginSuccess: action.status,
          loginOtpResp: action.otpResp,
        };
      case LOGIN_USER:
      return {
        ...state,
        loginResp: action.payload,
      };

      case FETCH_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

      case GET_CAPTCHA:
        return {
          ...state,
          captchaResp: action.payload,
          isValidCaptcha:false
        };

        case VERIFY_CAPTCHA:
        return {
          ...state,
          isValidCaptcha: action.payload,
        };
  
      
      case RESET_USER:
        return {
          ...state,
          isSignUpSuccess: false,
          statusCode:'',
          signUpResp:{},
          signupOtpResp:{},
          isOtpValid:false,
          isLoginSuccess:false,
          loginOtpResp:{},
          loginResp:{},
          userData:{}

        };
    default:
      return state;
  }
};
