
export const getUserData = () => {
    const userAuthData = JSON.parse(localStorage.getItem('userAuthData'));
    if (userAuthData) {
        return userAuthData;
    }
    else return {}
}

export const getUserToken = () => {
    const accessToken = localStorage.getItem('accessToken')?JSON.parse(localStorage.getItem('accessToken')):"";
    if (accessToken) {
        return accessToken;
    }
    else return false
}

export const setUserData = (data) => {
    localStorage.setItem('userAuthData',JSON.stringify(data));
}

export const setUserToken = (data) => {
    localStorage.setItem('accessToken',JSON.stringify(data));
}