import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from "../../redux/actions/login";
import profileIcon from '../../assets/images/profile-image.jpg'
import leftArrowIcon from '../../assets/icons/left-arrow.svg'
import cameraIcon from "../../assets/icons/camera-icon.png"
import UserImage from './UserImage'
import moment from "moment";
import { isEmpty } from 'lodash'

const EditProfile = ({ backtoUserProfile }) => {
    const { userData } = useSelector((state) => state.login);

    var { name, email, dateOfBirth, profilePicture, phoneNumber } = userData
    const [userInfo, setUserData] = useState({ name: name, email: email || "", dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD") || {} })

    const dispatch = useDispatch()
    const handleChange = (e) => {

        setUserData({ ...userInfo, [e.target.name]: e.target.value })
    }
    const editUserData = () => {
        if (!isEmpty(dateOfBirth)) {
            let [year, month, day] = userInfo.dateOfBirth.split("-")
            let data = { year, month, day }
            userInfo.dateOfBirth = data
        }
        dispatch(updateProfile(userInfo))
        backtoUserProfile()
    }

    return (
        <div className="edit-profile-wrap">
            <div className="edit-profile-header">
                <a onClick={backtoUserProfile}>
                    <img src={leftArrowIcon} alt="" />
                </a>
                <a onClick={editUserData}>Save</a>
            </div>
            <div className="edit-profile-body">
                <UserImage profileIcon={profileIcon} profilePicture={profilePicture} cameraIcon={cameraIcon} />
                <div className="user-inputs">
                    <div className="form-group">
                        <label >Name</label>
                        <input className="form-control" type="text" name="name" id="name" onChange={handleChange} placeholder="Enter name" value={userInfo.name} />
                    </div>
                    <div className="form-group">
                        <label >Mobile Number</label>
                        <div className="input-group">
                            <input className="form-control" type="text" name="code" id="code" placeholder="+91" value="+91" readOnly />
                            <input className="form-control" type="text" name="mobile" id="mobile" value={phoneNumber} readOnly />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="d-flex justify-content-between">
                            <label>Email</label>
                            {/* <label className="form-text text-muted">Optional</label> */}
                        </div>
                        <input className="form-control" type="email" name="email" onChange={handleChange} id="email" placeholder="Enter email" value={userInfo.email} />
                    </div>
                    <div className="form-group">
                        <label></label>
                        <input className="form-control" type="date" onChange={handleChange} name="dateOfBirth" id="dob" value={userInfo.dateOfBirth} />
                    </div>
                    <div className="form-group d-flex justify-content-center">
                        <button className="btn btn-primary" onClick={editUserData}>Save
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default EditProfile