import React from "react";
import { useHome } from '../../logics/Home/useHome'
import rightArrowIcon from '../../assets/icons/right-arrow.svg'
import { Link } from 'react-router-dom';

const Offer = ({ offers,type }) => {
    const offerData = offers;
    const logicProps = useHome()
    const { slideRight, slideLeft } = logicProps
    return (
        <section class="row">
            <div class="section-header">
                <div class="section-title">{type == "tile" ? "Tile Offers": "Combo Offers"}</div>
                {/* <a class="section-link">See all</a> */}
            </div>
            <div class="section-body shadowed">
                <div class="viewport">
                    {offerData?.length &&
                        (
                            offerData.map((offer) => {
                                return (
                                    <Link to={`/product-detail/${offer.productIds[0]}`} class="card card-5">
                                        <img src={offer.imageId} alt="" />
                                        <div class="card-content">
                                            {/* <span class="card-heading">{offer.offerDescription}</span> */}
                                            <div class="get-now mt-3">
                                                <span>Buy Now </span>
                                                <img src={rightArrowIcon} alt="" />
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        )}
                </div>
                <button class="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
                <button class="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button>
            </div>
        </section>



    )
}
export default Offer;
