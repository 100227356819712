import { GET_PRODUCT_LIST,GET_SIZES_LIST } from './types';
import tileEcomapi from '../../apis';

export const getProductList = (categoryId,subCategoryId,height,width,nextPageToken) => dispatch => {
    let url= ''
    if(height && width){
        url ='products?parentCategoryId='+categoryId+'&parentSubCategoryId='+subCategoryId+'&height='+height+'&width='+width
        if(nextPageToken)
        url ='products?parentCategoryId='+categoryId+'&parentSubCategoryId='+subCategoryId+'&height='+height+'&width='+width+'&nextPageToken='+nextPageToken
    }
   
    else{
        url = 'products?parentCategoryId='+categoryId+'&parentSubCategoryId='+subCategoryId
        if(nextPageToken)
        url = 'products?parentCategoryId='+categoryId+'&parentSubCategoryId='+subCategoryId+'&nextPageToken='+nextPageToken
    }
   


    tileEcomapi.get(url).then((resp) => {
        
        if (resp.data.statusCode == 200)
            dispatch({
                type: GET_PRODUCT_LIST,
                payload: resp.data.response,
                nextPageToken:nextPageToken
            })
    }).catch((error) => {
       console.log(error)
    })
}
export const getSizeList = (categoryId,subCategoryId) => dispatch => {
    tileEcomapi.get('sizes?parent_category_id='+categoryId+'&parent_sub_category_id='+subCategoryId).then((resp) => {
        
        if (resp.data.statusCode == 200)
            dispatch({
                type: GET_SIZES_LIST,
                payload: resp.data.response
            })
    }).catch((error) => {
       console.log(error)
    })
}
