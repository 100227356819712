import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux'
import { fetchSubCategories } from '../../redux/actions/home'
import { useHistory } from "react-router-dom";
import { resetUser } from "../../redux/actions/login";


export const useHeader = props => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [editView, setEditView] = useState(false);
  const [showMenuDrawer, setShowMenuDrawer] = useState(false);
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [showProductCategories, setShowProductCategories] = useState(false);
  const [showProductSubCategories, setShowProductSubCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch()
  const openDrawer = useCallback(() => {
    setShowDrawer(!showDrawer)

  }, [setShowDrawer, showDrawer])

  const openMenuDrawer = useCallback(() => {
    setShowMenuDrawer(!showMenuDrawer)
    if (showMenuDrawer) {
      setShowProductCategories(false)
      setShowProductSubCategories(false)
      setSelectedCategory('')
    }

  }, [setShowMenuDrawer, showMenuDrawer])

  const openProductCategories = useCallback(() => {
    setShowProductCategories(!showProductCategories)

  }, [setShowProductCategories, showProductCategories])

  const openProductSubCategories = useCallback((id, categoryName) => {
    dispatch(fetchSubCategories(id))
    setSelectedCategory(categoryName)
    setShowProductSubCategories(true)

  }, [setShowProductSubCategories, showProductSubCategories])

  const openNotificationDrawer = useCallback(() => {
    setShowNotificationDrawer(!showNotificationDrawer)

  }, [setShowNotificationDrawer, showNotificationDrawer])

  const EditProfileView = useCallback(() => {
    setEditView(!editView)
  })

  const handleSubCategoryClick = useCallback((sub_category) => {
    let path = `/product-listing/${selectedCategory}+${sub_category.parentId}/${sub_category.displayName}+${sub_category.id}`
    setShowMenuDrawer(false)
    history.push(path);
  }, [selectedCategory])

  const openSigninModal = useCallback(() => {
    setModalShow(true)
    console.log(modalShow);
  });

  const closeSigninModal = useCallback(() => {
    dispatch(resetUser());
    setModalShow(false)
    console.log(modalShow, "closeSigninModal");

  });


  return {
    openDrawer,
    openMenuDrawer,
    openNotificationDrawer,
    openProductCategories,
    openProductSubCategories,
    EditProfileView,
    handleSubCategoryClick,
    openSigninModal,
    closeSigninModal,
    showMenuDrawer,
    showDrawer,
    showNotificationDrawer,
    showProductCategories,
    showProductSubCategories,
    editView,
    selectedCategory,
    modalShow
  }
}