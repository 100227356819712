import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import { useHeader } from "./logics/Header/useHeader"


import Home from './pages/Home'
import Cart from './pages/Cart'
import Auth from "./pages/Auth";
import Category from "./pages/Category";
import PrivacyPolicy from "./pages/SiteContent/PrivacyPolicy";
import ShippingPolicy from './pages/SiteContent/ShippingPolicy';
import Header from './components/Header'
import Footer from './components/Footer'
import ProductListing from "./pages/ProductListing";
import MyOrders from "./pages/MyOrders";
import PurchaseHistory from "./pages/PurchaseHistory";
import WishList from "./pages/WishList";
import MyAddress from "./pages/MyAddress";
import AddAddress from "./pages/MyAddress/AddAddress";
import ProductDetail from "./pages/ProductDetail";
import '../node_modules/react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { getUserToken, getUserData } from "./logics/User/userData";
import ScrollToTop from "./logics/ScrollToTop";
import { resetUser } from "./redux/actions/login";
import Faq from './pages/SiteContent/Faq';



const PrivateRoute = ({ component: Component, openSigninModal, ...rest }) => {
  var accessToken = getUserToken();
  if (!accessToken)
    openSigninModal(true)
  return (

    // Show the component only when the user is logged in Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      accessToken ?
        <Component {...props} />
        : <Redirect to="/" />
    )} />
  );
};

function App() {
  const dispatch = useDispatch()
  const logicProps = useHeader();
  const {
    openSigninModal,
    closeSigninModal,
    modalShow
  } = logicProps;


  const { loginOtpResp } = useSelector((state) => state.login)
  // useEffect(() => {
  //   if (loginOtpResp.status)
  //     openSigninModal()
  // }, [loginOtpResp]);

  return (
    <Router basename={'/'}>
      <div className="App">
        <ScrollToTop />
        <Header getUserData={getUserData} openSigninModal={openSigninModal} />
        <Switch>
          <Route exact path='/' component={Home}></Route>
          <PrivateRoute exact path='/cart' component={Cart} openSigninModal={openSigninModal}></PrivateRoute>
          <Route exact path='/product-listing/:categoryId/:subCategoryId' component={ProductListing} openSigninModal={openSigninModal} />
          <PrivateRoute exact path='/my-orders' component={MyOrders} openSigninModal={openSigninModal}></PrivateRoute>
          <PrivateRoute exact path='/purchase-history' component={PurchaseHistory} openSigninModal={openSigninModal}></PrivateRoute>
          <PrivateRoute exact path='/wishlist' component={WishList} openSigninModal={openSigninModal}></PrivateRoute>
          <PrivateRoute exact path='/my-addresses' component={MyAddress} openSigninModal={openSigninModal}></PrivateRoute>
          <PrivateRoute exact path='/add-address' component={AddAddress} openSigninModal={openSigninModal}></PrivateRoute>
          <Route exact path='/product-detail/:id' component={ProductDetail}></Route>
          <Route exact path='/categories' component={Category}></Route>
          <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
          <Route exact path='/shipping-policy' component={ShippingPolicy}></Route>
          <Route exact path='/faq' component={Faq}></Route>
        </Switch>
        <Footer />
        {modalShow && <Auth show={modalShow} closeSigninModal={closeSigninModal} />}
      </div>
      <ToastContainer /> <ToastContainer />
    </Router>
  );
}

export default App;
