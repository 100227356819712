import {useState,useEffect,useCallback } from 'react';
import { selectCategoryId,fetchCategories } from '../../redux/actions/home'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";
export const useBreadCrumbs = props => {
    const {tabs} = props
    const history = useHistory();
    const dispatch = useDispatch()
    const {categoriesData } = useSelector((state) => state.home);
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const location = useLocation();
    useEffect(()=>{
        dispatch(fetchCategories())
        if(location.pathname == "/add-address")
        setActiveTab(tabs[1])
    },[])
    const selectActiveItem = useCallback((item) =>{
        categoriesData && categoriesData.length>0 && categoriesData.map((category)=>{
            if(category.displayName == item){
                dispatch(selectCategoryId(category))
                history.push('/categories');
            }
        })
        
        setActiveTab(item)
      },[activeTab,setActiveTab,categoriesData])

      const redirectToMyaddress = useCallback(() =>{
        history.push('/my-addresses');
      },[])
    return{
        activeTab,
        selectActiveItem,
        redirectToMyaddress
    }
}