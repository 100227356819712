import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'

const ProductListModal = ({ show, handleClose, product }) => {
    const data = product.items;
    const close = () => {
        handleClose();
    };
    console.log(product);

    return (

        <Modal show={show} onHide={close} centered size="lg">
            <div class="modal-content">
                <Modal.Header closeButton />
                <div class="modal-body">

                    <div className="my-basket categories">
                        <div className="category">
                            <div className="category-title"></div>
                            <div className="cart-items-wrap">
                                {data?.length &&
                                    data.map(data => {
                                        return (

                                            <div className="card cart-item">
                                                <div className="item-image">
                                                    <img src={data?.displayImage} alt="" />
                                                </div>
                                                <div className="item-body">
                                                    <div className="item-name">{data?.name} {data?.length > 1 ? `+ ${data?.length - 1}` : ""}</div>
                                                    <div className="item-quantity">{data?.price} x {data?.units} = {data?.price * data?.units}</div>
                                                    <div className="item-spec">
                                                        Units: <strong>{data?.units} Pack</strong> <strong>|</strong> Size: <strong>{data?.size}</strong>
                                                    </div>
                                                    {data?.deliveryPeriod?.outOfStock ?
                                                        <div className="item-spec">
                                                            <span className="text-success">Estimated delivery in {data?.inStock ? data?.deliveryPeriod?.inStock : data?.deliveryPeriod?.outOfStock} days</span>
                                                        </div> : null}
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <ul class="list-group mt-2">
                                <li class="list-group-item d-flex justify-content-between">
                                    <div>Order ID</div>
                                    <div>#{product?.id}</div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <div>Cart Amount</div>
                                    <div>₹{product?.orderTotal+parseInt(product?.orderDiscount)}</div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <div>Shipping Charge</div>
                                    <div>₹{product?.deliveryCharge}</div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <div>Offer Discount</div>
                                    <div>₹{product?.orderDiscount}</div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <div>Paid Amount</div>
                                    <div>₹{product?.amountPaid}</div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <div>Balance Amount</div>
                                    <div>{product?.orderTotal - product?.amountPaid <= 0 ? "Nil" : product?.orderTotal - product?.amountPaid}</div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between text-danger">
                                    <div>Grand Total</div>
                                    <div>₹{product?.orderTotal}</div>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </Modal>
    );
};
export default ProductListModal;
