

import React, { useState } from "react";
import arrowIcon from '../../../assets/icons/right-arrow-red.svg'
import { updateProfile } from "../../../redux/actions/login";
import { useDispatch ,useSelector} from 'react-redux'
const SignUp = () => {
    const [loginData, setLoginData] = useState({ name: ""})
    const { userData } = useSelector((state) => state.login);

    const dispatch = useDispatch()
    const handleChange = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value })
    }
    const updateUserName = () => {
        dispatch(updateProfile(loginData))
    }
    return (
        <div className="login-modal">
            <div className="logo">
                <img src="assets/logo/logo-full-2x.png" alt="" />
            </div>
            <div className="body">
                <div className="phone-text">Enter you full name</div>
                <div className="phone-wrap">
                    <input type="text" name="name" id="phone" onChange={handleChange} placeholder="Enter you name" />
                    <button className="btn" onClick={updateUserName} ><img src={arrowIcon} alt="arroe" />
                    </button>
                </div>
            </div>
            <div className="footer">
                <a className="social d-flex">
                    <img src="assets/icons/right-arrow.svg" alt="" />
                </a>
                <div className="info">
                    By continuing you may receive an SMS for verification. Message and data rates may apply.
                </div>
            </div>
        </div>
    );
}
export default SignUp;

