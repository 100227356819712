import React from "react";
import ProfileSection from "./ProfileSection";
import HomeTopBanner from "../Banners/HomeTopBanner";

const ProfileGridSection = ({bannerList,userData}) =>{
 return(
    <section class="row welcome ml-0">
        <ProfileSection userData={userData}/>
        <HomeTopBanner bannerList={bannerList} />
</section>
 )
}
export default ProfileGridSection;
