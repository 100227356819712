import React, { useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import ProductCategoriesList from './ProductCategoriesList'
import ProductSubCategoryList from './ProductSubCategoryList'
import { useOutsideClickDetect } from "../../logics/OutsideClick/useOutsideClickDetect";
import { fetchCategories } from '../../redux/actions/home'
// import profileIcon from '../../assets/images/profile-image.jpg'
const MenuDrawer = ({ openProductCategories, openProductSubCategories, showProductCategories, showProductSubCategories, closeMenu, selectedCategory, handleSubCategoryClick }) => {
    const wrapperRef = useRef(null);
    const dispatch = useDispatch()
    const { userData } = useSelector((state) => state.login);

    const { categoriesData, subCategoryData } = useSelector((state) => state.home);
    useOutsideClickDetect(wrapperRef, closeMenu);
    useEffect(() => {
        if (categoriesData.length == 0)
            dispatch(fetchCategories())
    }, [])
    return (

        <div className="menubar">

            <div className="menubar-body" ref={wrapperRef}>
                <div className="menu">
                    <a className="menu-close" onClick={closeMenu}>✕</a>
                    <span className={showProductSubCategories ? "menu-left" : null}>
                        <div className="menu-links">
                            <a href="#" className="link" onClick={openProductCategories}>Product Categories</a>
                            {showProductCategories ? (<ProductCategoriesList openProductSubCategories={openProductSubCategories} categories={categoriesData} selectedCategory={selectedCategory} />) : null}
                            <Link className="link" to='/my-orders' onClick={closeMenu}>My Orders</Link>
                            <Link className="link" to='/wishlist' onClick={closeMenu}>My Wishlist</Link>
                            <Link className="link" to='/my-addresses' onClick={closeMenu}>My Address</Link>
                            {userData.userType == "3" && <Link className="link" to='/purchase-history' onClick={closeMenu}>Purchase History</Link>}
                        </div>
                        <div className="menu-footer">
                            {/* <a href="#" className="link">Settings</a> */}
                            <a href="#" className="link">Help Center</a>
                        </div>
                    </span>
                    {showProductSubCategories ? <ProductSubCategoryList subCategories={subCategoryData} selectedCategory={selectedCategory} handleSubCategoryClick={handleSubCategoryClick} /> : null}
                </div>
            </div>
        </div>

    )
}
export default MenuDrawer