
import mapCardimg from '../../assets/images/map-small.png'
import heartIcon from '../../assets/icons/heart-icon.svg'
import deleteIcon from '../../assets/icons/bin-icon.svg'

import { deleteAddress } from "../../redux/actions/home";
import { useDispatch, useSelector } from 'react-redux'
import {AiOutlineStar} from "react-icons/ai"
import {FaStar} from "react-icons/fa"

const AddressCard = ({ address, showIcon = true ,defaultAddress,handleStoreDefaultStore}) => {
   
    const dispatch = useDispatch()

    const deleteAddr = (id) => {
        dispatch(deleteAddress(id))
    }
    return (
        <div className="address-card">
            <img src={mapCardimg} alt="" />
            <div className="address">
                <div className="street">{address?.nickName}</div>
                <div className="street">{address?.addressLineOne} {address?.addressLineTwo} </div>
                <div className="city">{address?.district} {address?.state} {address?.pincode}</div>
            </div>
            {showIcon &&
                <>
                    <a ><img className="heart" src={heartIcon} alt="" /></a>
                    <a onClick={() => deleteAddr(address.id)}><img className="delete" src={deleteIcon} alt="" /></a>
                   
                </>
            }
            <a className="star">
            {defaultAddress == address.id ? (<FaStar />):(
                <AiOutlineStar onClick={()=>handleStoreDefaultStore(address.id)}/>
            )}
              </a>
        </div>
    )
}
export default AddressCard