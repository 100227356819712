import React from "react";
import homeIcon from '../../assets/icons/home-icon.svg'
import phoneIcon from '../../assets/icons/phone-icon.svg'
import mailIcon from '../../assets/icons/mail-icon.svg'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="row justify-content-start">
                <div className="col-md-1 col-3">
                    <div className="footer-heading">Discover</div>
                    <div className="footer-links">
                        <Link to="/privacy-policy" className="link">Privacy Policy</Link>
                        <Link to="/shipping-policy" className="link">Shipping Policy</Link>
                        <Link to="/faq" className="link">FAQ</Link>
                    </div>
                </div>
                <div className="col-md-1 col-3">
                    <div className="footer-heading">About</div>
                    <div className="footer-links">
                        <a className="link">Team</a>
                        <a className="link">Careers</a>
                        <a className="link">Blog</a>
                    </div>
                </div>
                <div className="col-md-2 col-6">
                    <div className="footer-heading">Get in Touch</div>
                    <div className="footer-links">
                        <div className="link-container">
                            <img src={homeIcon} alt="" />
                            <a className="link">2nd Floor, F.A. Tower, Kaloor - Kadavanthara Rd, Ernakulam, Kerala 682017</a>
                        </div>
                        <div className="link-container">
                            <img src={phoneIcon} alt="" />
                            <a href="tel:+91-8086669299" className="link">8086669299</a>
                        </div>
                        <div className="link-container">
                            <img src={mailIcon} alt="" />
                            <a href="mailto:enquiry@mywarehouseonline.com" className="link">enquiry@mywarehouseonline.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                Copyright ©️ {new Date().getFullYear()}. All rights reserved
            </div>
        </footer>
    )
}
export default Footer;
