import React from "react";
import { useHome } from '../../logics/Home/useHome'
import rightArrowIcon from '../../assets/icons/right-arrow.svg'
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const OfferBanner = ({ offerBannerData }) => {
  const history = useHistory();

  const bannerListData = offerBannerData;
  const logicProps = useHome()
  const { slideRight, slideLeft } = logicProps
  const navigateTo = (banner) => {
   
    // let path = `/product-listing/+""+${banner.categoryId}/+${banner.subcategoryId}`
    let path = `/product-listing/+${banner.categoryId}/+${banner.subcategoryId}`

    history.push(path);
  }

  return (
    <section class="row">
      <div class="section-body shadowed">
        <div class="viewport">
          {(bannerListData && bannerListData.length > 0) &&
            (
              bannerListData.map((banner) => {
                return (
                  <a onClick={() => navigateTo(banner)} class="card card-4">
                    <img src={banner.bannerImage} alt="" />
                    <div class="card-content">
                      {/* <span class="card-heading">{banner.title}</span> */}
                      <div class="price-wrap">
                        <span class="price-old"></span>
                        <span class="percentage-off"></span>
                        <span class="price"></span>
                      </div>
                      <div class="buy-now p-4">
                        {/* <span>Buy Now</span> */}
                        <img src={rightArrowIcon} alt="" />
                      </div>
                    </div>
                  </a>

                )
              })
            )}
        </div>
        <button class="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
        <button class="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button>
      </div>
    </section>
  )
}
export default OfferBanner;
