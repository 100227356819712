import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Breadcrumbs from "../../components/Breadcrumbs"
import './MyAddress.css'
import Modal from 'react-bootstrap/Modal'
import { fetchAddress, addAddress } from "../../redux/actions/home";
import Autocomplete from "react-google-autocomplete";
import { isEmpty } from 'lodash'
import GoogleMapReact from 'google-map-react';
import { MapKey } from '../../constants/constants'


const AddAddress = () => {
    const dispatch = useDispatch()

    let breadCrumbTabs = ["My Addresses", "Add New Address"];
    const AnyReactComponent = () => <i class="fa fa-map-marker fa-5x text-danger" aria-hidden="true"></i>
        ;

    const { addressStatus } = useSelector((state) => state.home);
    const [tempAddress, setTempAddress] = useState({});

    const [addressData, setAddressData] = useState({
        nickName: "", addressLineOne: "", addressLineTwo: "", pincode: "",
        state: 17, gst: "", googlePlaceId: "", latitude: '', longitude: '',
        district: 2,
    })

    const defaultProps = {
        center: {
            lat: addressData.latitude ? addressData.latitude : 9.931233,
            lng: addressData.longitude ? addressData.longitude : 76.267303
        },
        zoom: 10
    }


    const handleChange = (e) => {
        setAddressData({ ...addressData, [e.target.name]: e.target.value })
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
      
        if (!isEmpty(tempAddress)) {
            const data = {
                nickName: addressData.nickName,
                addressLineOne: tempAddress?.address_components[0]?.long_name,
                addressLineTwo: `${tempAddress?.address_components[1]?.long_name}`,
                pincode: tempAddress?.address_components[4]?.long_name,
                googlePlaceId: tempAddress?.place_id,
                latitude: tempAddress.geometry['location'].lat(),
                longitude: tempAddress.geometry['location'].lng(),
                state: 17,
                district: 2

            }
            setAddressData(data);
        }
    }


    const handleSaveAddress = () => {
        dispatch(addAddress(addressData))

    };

    useEffect(() => {
        dispatch(fetchAddress())
        if (addressStatus) {
            handleClose()
        }
    }, [addressStatus])

    return (
        <>
            <div className="add-address-wrap">
                <Breadcrumbs tabs={breadCrumbTabs} />
                <div className="address-search-wrap">
                    {/* <input type="text" name="address-search" placeholder="Search Address" id="address-search" />
                     */}
                    <Autocomplete id="address-search"
                        apiKey={MapKey}
                        onPlaceSelected={(place) => {
                            setTempAddress(place)
                        }}
                        options={{
                            types: ["(regions)"],
                            componentRestrictions: { country: "in" },
                        }}
                    />
                    <a className="btn btn-save" onClick={handleShow}>Select Location</a>
                </div>
                <div className="map-wrap">
                    <div style={{ height: '70vh', width: '100%' }}>

                        <GoogleMapReact
                            bootstrapURLKeys={{ key: MapKey }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}>
                            <AnyReactComponent
                                lat={addressData.latitude}
                                lng={addressData.longitude}
                                text="My Marker"
                            />
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>

                <div className="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Address</Modal.Title>
                    </Modal.Header>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="form-group p-2">
                                    <label for="inputEmail4">Name</label>
                                    <input type="text" className="form-control" value={addressData.nickName} onChange={handleChange} name="nickName" placeholder="Enter name" />
                                </div>
                                <div className="form-group p-2">
                                    <label for="inputEmail4">Address Line 1</label>
                                    <input type="text" className="form-control" value={addressData.addressLineOne} onChange={handleChange} name="addressLineOne" placeholder="Enter address 1" />
                                </div>
                                <div className="form-group p-2">
                                    <label for="inputEmail4">Address Line 2</label>
                                    <input type="text" className="form-control" value={addressData.addressLineTwo} onChange={handleChange} name="addressLineTwo" placeholder="Enter address 2" />
                                </div>
                                <div className="form-group p-2">
                                    <label for="inputEmail4">Pincode</label>
                                    <input type="text" className="form-control" value={addressData.pincode} onChange={handleChange} name="pincode" placeholder="Enter pincode" />
                                </div>
                                <div className="form-group p-2">
                                    <label for="inputEmail4">Gst Number: (Optional)</label>
                                    <input type="text" className="form-control" value={addressData.gst} onChange={handleChange} name="gst" placeholder="Enter gst number" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={handleSaveAddress}>Save Location</button>
                        <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Cancel</button>
                    </div>
                </div>

            </Modal>
        </>

    )
}
export default AddAddress