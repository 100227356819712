import React, { useEffect, useState } from "react";
import imageDummy from "../../assets/images/demo-product.jpeg";
import ImageModal from "../../components/ImageModal"
import { useHome } from '../../logics/Home/useHome'

const ImageWrap = (data) => {
  const { product: { productImages } } = data;

  const logicProps = useHome()
  const { slideRight, slideLeft } = logicProps

  const [showState, setShowState] = useState(false);
  const [index, setIndex] = useState(null);

  const handleImageModal = (index) => {
    setShowState((showState) => showState = !showState)
    setIndex(index);
  }

  return (
    <>

      <section className="row ml-0">
        
        <div className="section-body specials">
          <div className="viewport">
            {(productImages?.length) &&
              productImages.map((item) => {
                return (
                  <a className="card card-1" onClick={() => handleImageModal(index)}>
                    <img src={item||imageDummy} alt="image"/>
                  </a>
                )
              })
            }
          </div>
          <button className="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
          <button className="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button>
        </div>
      </section>

      {showState && <ImageModal images={productImages} index={index} show={showState} handleClose={handleImageModal} />}

    </>
  );
};
export default ImageWrap;
