import React, { useState, useEffect } from "react";
import arrowIcon from '../../../assets/icons/right-arrow-red.svg'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { signUpOtpVerify, loginOtpVerify, signUpUser, resetUser } from "../../../redux/actions/login";

const LoginCode = ({ userData = {} }) => {

    const [otpNumber, setLoginData] = useState({ otp: "" })
    const [timeLeft, setTimeLeft] = useState(60);
    const dispatch = useDispatch()
    const { otpResp, isSignUpSuccess, loginResp } = useSelector((state) => state.login);
    const { phoneNumber, countryCode, userType } = userData;

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(0)
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const loginUsingMob = () => {
        if (otpNumber.otp.length != 6) {
            toast.error("Please enter valid a otp");
            return
        }
        if (isSignUpSuccess) {
            dispatch(signUpOtpVerify(otpNumber))
        }

        else {
            dispatch(loginOtpVerify(otpNumber))
        }
    }

    const resendOtp = () => {
        const req = { phoneNumber, countryCode, userType }
        dispatch(signUpUser(req))
        if (loginResp?.status) {
            setTimeLeft(60)
        }

    }
    const handleChange = (e) => {
        setLoginData({ [e.target.name]: e.target.value })
    }

    const reset = () => {
        dispatch(resetUser())
    };


    return (
        <>
            <div class="login-modal">
                <div class="logo">
                    <img src="assets/logo/logo-full-2x.png" alt="" />
                </div>
                <div class="body ">
                    <div class="otp-text-1">Please enter the 4-digit code sent to you at</div>
                    <div class="otp-phone" onClick={reset}>{phoneNumber}</div>
                    <div class="otp-wrap">
                        <input type="tel" name="otp" placeholder="enter otp" maxLength="6" onChange={handleChange} />
                        <button type="button" className="btn" onClick={loginUsingMob}><img src={arrowIcon} alt="arroe" /></button>
                    </div>
                    <div class="otp-text-1">I haven't received a code</div>
                    <div class="otp-text-2">RESEND CODE in {timeLeft} Sec</div>
                    {timeLeft == 0 && <div class="otp-wrap">
                        <button type="button" className="btn pnk" onClick={resendOtp} >Resend</button>
                    </div>
                    }
                </div>
                <div class="footer">
                    {/* <a class="social d-flex" href="login-3.html">
                        <div>Or Connect using Social Media</div>
                        <img src="assets/icons/right-arrow.svg" alt="" />
                    </a> */}
                    <div class="info">
                        By continuing you may receive an SMS for verification. Message and data rates may apply.
                    </div>
                </div>
            </div>
        </>
    );
}
export default LoginCode;
