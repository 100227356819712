import React from "react";
import { useHome } from "../../logics/Home/useHome";
import rightArrowIcon from "../../assets/icons/right-arrow.svg";
import { useHistory } from "react-router-dom";
const BottomBanner = ({ bannerList }) => {
  const banners = bannerList;
  const history = useHistory();

  const logicProps = useHome();
  const { slideRight, slideLeft } = logicProps;
  const navigateTo = (banner) => {
    let path = `/product-listing/+${banner.categoryId}/+${banner.subcategoryId}`;
    history.push(path);
  };
  return (
    banners?.length > 0 && (
      <section className="row ml-0">
        <div className="section-body specials">
          <div className="viewport">
            {banners.map((banner) => {
              return (
                <a className="card large-card" onClick={() => navigateTo(banner)}>
                  <img src={banner.bannerImage} />
                  <div className="card-content">
                    <span className="card-heading">{banner.title}</span>
                    <br />
                    <img src={rightArrowIcon} alt="" />
                  </div>
                </a>
              );
            })}
          </div>
          <button
            className="slideLeft"
            id="slideLeft"
            type="button"
            onClick={(e) => slideLeft(e)}
          ></button>
          <button
            className="slideRight"
            id="slideRight"
            type="button"
            onClick={(e) => slideRight(e)}
          ></button>
        </div>
      </section>
    )

  )
}
export default BottomBanner;
