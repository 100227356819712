import React from "react";
import './ProductListing.css'
import FilterBar from "./FilterBar";
import { useProductListing } from "../../logics/ProductListing/useProductListing";
const FilterBox = ({sizeList,handleClickSizeFilter,selectedSizeActive,handleClickSizeAll}) =>{ 
    const logicProps = useProductListing();
    const {
        showFilterBar,
        setShowFilterBar
      } = logicProps;
    return(
        <div class="row filter-box">
            <div class="col d-flex">
            <a  class={`filter ${selectedSizeActive == 0 ? 'active':''}`} onClick={handleClickSizeAll}>All</a>
            {sizeList && sizeList.length > 0 && sizeList.map((size) => (
                
                <a  class={`filter ${selectedSizeActive == size.id ? 'active':''}`} onClick={()=>handleClickSizeFilter(size)}>{size.displayName}</a>
                // {/* <a  class="filter active">120 x 60</a> */}
                
            
            ))  }
            </div>
            {/* <div class="col d-flex justify-content-lg-end">
                <a  class="filter">Sort</a>
                <a  class="filter active">Price</a>
                <a  class="filter">Review</a>
                
                <a class="filter" onClick={()=> setShowFilterBar(true)} >+4 Filters</a>
            </div>
            {showFilterBar ? (<FilterBar />):null} */}
        </div>
    )
    
 
}
export default FilterBox