import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import React, { useEffect } from "react";
const DetailWrapModal = ({ product, showDetails, handleCloseShowDetails }) => {

    return (
        <Modal show={showDetails} onHide={handleCloseShowDetails} size="lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">Details</div>
                    <button type="button" onClick={handleCloseShowDetails} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body item-details">
                    <div class="details-wrap">
                        {/* <div class="title">Details</div> */}
                        <div class="detail-table row">
                            <div className="col">
                                <div className="detail d-flex my-3">
                                    <div className="key">Price</div>
                                    <div className="value">{product.price} Per Piece</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Size</div>
                                    <div className="value">{product.size}</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Ideal For</div>
                                    <div className="value">{product.idealFor}</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Quality</div>
                                    <div className="value">{product.quality}</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Material Type</div>
                                    <div className="value">{product.materialType}</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Finish</div>
                                    <div className="value">{product.finish}</div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="detail d-flex my-3">
                                    <div className="key">Brand</div>
                                    <div className="value">{product.brand}</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Thickness</div>
                                    <div className="value">{product.thickness}</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Rate Per Sqft</div>
                                    <div className="value">{product.ratePerSquareFeet}</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Cover Area</div>
                                    <div className="value">{product.coverArea} Sq.Ft.</div>
                                </div>
                                <div className="detail d-flex my-3">
                                    <div className="key">Weight Per Piece</div>
                                    <div className="value">{product.weight} Kg</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default DetailWrapModal
