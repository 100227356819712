import React, { useState } from "react";
import arrowIcon from '../../../assets/icons/right-arrow-red.svg'
import { signUpUser, getCaptcha, verifyCaptcha } from "../../../redux/actions/login";
import { useDispatch, useSelector } from 'react-redux'
import { countryCode } from '../../../constants/constants'
import { toast } from 'react-toastify';
import { useEffect } from "react";

const Login = ({ setUserData }) => {
    const [captcha, setCaptcha] = useState([])

    const [loginData, setLoginData] = useState({ phoneNumber: "", countryCode: "+91", userType: "2", captcha: "" })
    const { captchaResp, isValidCaptcha } = useSelector((state) => state.login);
    const dispatch = useDispatch()
    const handleChange = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value })
        setUserData({ ...loginData, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        genarateCaptcha();
        console.log(isValidCaptcha);

    }, []);

    useEffect(() => {
        setCaptcha(captchaResp.image);
    }, [captchaResp]);

    useEffect(() => {
        console.log(isValidCaptcha);
        if (loginData.phoneNumber && isValidCaptcha == 'true') {
            delete loginData.captcha;
            dispatch(signUpUser(loginData));
        }
        if(loginData.phoneNumber && isValidCaptcha == 'false'){
            delete loginData.captcha;
            toast.error("Captcha does not match");
            genarateCaptcha()
        }
    }, [isValidCaptcha]);

    const genarateCaptcha = () => {
        dispatch(getCaptcha(loginData))
    }

    const verifyLoginCaptcha = () => {
        if (loginData.captcha) {
            const data = {
                id: captchaResp.id,
                value: loginData.captcha
            }
            dispatch(verifyCaptcha(data))
        }
    }

    return (
        <div className="login-modal">
            <div className="logo">
                <img src="assets/logo/logo-full-2x.png" alt="" />
            </div>
            <div className="body">
                <div className="phone-text">Let's Get Started</div>
                <div className="phone-wrap">
                    <input type="text" list="data" defaultValue="+91" name="countryCode" id="code" onChange={handleChange} placeholder="Code" />
                    <datalist id="data">
                        {countryCode.map((item, key) =>
                            <option key={key} value={item.dial_code} >{item.name}</option>
                        )}
                    </datalist>
                    <input type="text" name="phoneNumber" id="phone" onChange={handleChange} placeholder="Enter your phone number" />
                </div>
                {loginData?.phoneNumber?.length >= 10 && <div className="phone-wrap">
                    {captcha ?
                        <img className="captcha" src={`${captcha}`} /> :
                        ''}
                    <i class="fa fa-refresh fa-2x cursor" onClick={genarateCaptcha} />
                    <input type="text" name="captcha" id="captcha" onChange={handleChange} placeholder="Enter captcha" />
                    <button className="btn" onClick={verifyLoginCaptcha} ><img src={arrowIcon} alt="arrow" />
                    </button>

                </div>}

            </div>
            <div className="footer">
                <a className="social d-flex" href="login-3.html">
                    {/* <div>Or Connect using Social Media</div> */}
                    <img src="assets/icons/right-arrow.svg" alt="" />
                </a>
                <div className="info">
                    By continuing you may receive an SMS for verification. Message and data rates may apply.
                </div>
            </div>
        </div>
    );
}
export default Login;
