import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import FilterBox from "./FilterBox";
import CardProduct from "./CardProduct";
import { useDispatch, useSelector } from 'react-redux'
import { getProductList, getSizeList } from "../../redux/actions/productlist"
import { addItemtoWishlist, removeItemFromWishlist, getWishlist, refreshWishlistStatus } from "../../redux/actions/wishlist"
import './ProductListing.css'
import { useParams, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const ProductListing = () => {
  let breadCrumbTabs = ["Categories"]
  const dispatch = useDispatch()
  const [projectSelected, setProjectSelected] = useState({});
  const [selectedSizeActive, setSelectedSizeActive] = useState(0);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();


  const { productList, sizeList, nextPageToken } = useSelector((state) => state.products);
  const { itemAddedToWishlist, itemRemovedFromWishlist } = useSelector((state) => state.wishlist);
  breadCrumbTabs.push(params.categoryId.split("+")[0])
  breadCrumbTabs.push(params.subCategoryId.split("+")[0])
  useEffect(() => {
    let categoryId = params.categoryId.split("+")[1]
    let subCategoryId = params.subCategoryId.split("+")[1]
    dispatch(getProductList(categoryId, subCategoryId, '', '', ''))
    dispatch(getSizeList(categoryId, subCategoryId))
  }, [params])
  useEffect(() => {

    if (itemAddedToWishlist || itemRemovedFromWishlist) {
      projectSelected.isWishListed ? projectSelected.isWishListed = 0 : projectSelected.isWishListed = 1
      dispatch(refreshWishlistStatus())
    }

  }, [itemAddedToWishlist, itemRemovedFromWishlist])
  const handleChangeWishlist = (product) => {
    setProjectSelected(product)
    const body = {
      productIds: [product.productId]
    }
    if (product.isWishListed) {

      dispatch(removeItemFromWishlist(body))
    }
    else
      dispatch(addItemtoWishlist(body))
  }

  const handleOnClick = (productId) => {
    history.push(`/product-detail/${productId}`)
  };

  const handleClickSizeFilter = (size) => {
    let categoryId = params.categoryId.split("+")[1]
    let subCategoryId = params.subCategoryId.split("+")[1]
    dispatch(getProductList(categoryId, subCategoryId, size.height, size.width, ''))
    setSelectedSizeActive(size.id)
  };
  const handleClickSizeAll = () => {
    let categoryId = params.categoryId.split("+")[1]
    let subCategoryId = params.subCategoryId.split("+")[1]
    dispatch(getProductList(categoryId, subCategoryId, '', '', ''))
    setSelectedSizeActive(0)
  };
  const loadMoreProducts = () => {
    let categoryId = params.categoryId.split("+")[1]
    let subCategoryId = params.subCategoryId.split("+")[1]

    dispatch(getProductList(categoryId, subCategoryId, '', '', nextPageToken))
  }
  return (
    <>
      <div className="listing-options">
        <Breadcrumbs tabs={breadCrumbTabs} />
        <FilterBox sizeList={sizeList} handleClickSizeFilter={handleClickSizeFilter} selectedSizeActive={selectedSizeActive} handleClickSizeAll={handleClickSizeAll} />
      </div>
      <div class="products">
        {productList && productList.length > 0 &&
          productList.map((product) => (
            <span className="cursor" onClick={() => handleOnClick(product?.productId)}>
              <CardProduct cardProductDetail={product} handleChangeWishlist={handleChangeWishlist} />
            </span>

          ))}

      </div>
      <div class="d-flex justify-content-center p-4">
      <button className="btn btn-danger btn-sm" onClick={loadMoreProducts}><strong>Load more</strong></button>
      </div>
    </>
  )


}
export default ProductListing