import React from "react";
import Grids from "./Grids";
import { Link } from "react-router-dom";

const CategoriesGrid = ({ CategotyList, type = "", title = "" }) => {
  return (
    <section class="row">
      <div class="section-header">
        <div class="section-title">{title}</div>
        {type && (
          <Link to="/categories" class="section-link">
            {type}
          </Link>
        )}
      </div>
      <Grids CategotyList={CategotyList} />
    </section>
  );
};
export default CategoriesGrid;
