import React from "react";
import Modal from 'react-bootstrap/Modal'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';


const ImageModal = ({ show, handleClose, images = [],index=0 }) => {
    const close = () => {
        handleClose();
    };

    const slideImages = images;

    return (

        <Modal show={show} onHide={close} centered size="lg">
            <div class="modal-content">
                <Modal.Header closeButton />
                <div class="modal-body image-modal">
                    <div className="text-center">

                        <Slide defaultIndex={index}>
                            {slideImages.map((slideImage, index) => (
                                <div className="each-slide" key={index}>
                                    <img class="img-fluid" src={slideImage} alt="" />
                                </div>
                            ))}
                        </Slide>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default ImageModal;
