import React, { useRef } from "react";
import profileIcon from '../../assets/images/profile-image.jpg'
import cameraIcon from "../../assets/icons/camera-icon.png"
import UserImage from "./UserImage";
import { useDispatch, useSelector } from 'react-redux'
import { useOutsideClickDetect } from "../../logics/OutsideClick/useOutsideClickDetect";
import { logoffUser } from "../../redux/actions/login";
import { removeCartCount } from "../../redux/actions/product";
import { Link } from 'react-router-dom';
import './ProfileDrawer.css'
import { getUserData } from "../../logics/User/userData";
import editIcon from "../../assets/icons/edit-icon.svg"


const ProfileDrawer = ({ closeProfileDrawer, EditProfileView, openSigninModal }) => {
    const userData = getUserData();

    const dispatch = useDispatch()
    const gotoSignin = () => {
        openSigninModal(true)
        closeProfileDrawer()
    }

    const logoutUser = () => {
        dispatch(logoffUser())
        dispatch(removeCartCount())
        closeProfileDrawer()
    }

    const wrapperRef = useRef(null);
    useOutsideClickDetect(wrapperRef, closeProfileDrawer);
    var { email, userType, profilePicture, phoneNumber, countryCode, profilePicture } = userData;
    return (
        <>
            <div className="sidebar" >
                <span ref={wrapperRef}>
                    <a className="profile-close" onClick={closeProfileDrawer}>✕</a>
                    {/* <a className="sidebar-close" onClick={closeProfileDrawer}>✕</a> */}

                    <div className="sidebar-header" >
                        {userType && <a className="edit-wrap" onClick={EditProfileView}>
                            <img src={editIcon} alt="" />
                            <span>Edit Profile</span>
                        </a>}
                    </div>
                    <div className="sidebar-body" >
                        <div>
                            <UserImage profileIcon={profileIcon} profilePicture={profilePicture} cameraIcon={cameraIcon} />
                            <div className="user-basic">
                                {!phoneNumber && (
                                    <span className="user-name">Guest User</span>
                                )}
                            </div>
                            {phoneNumber ? (<div className="user-contact">
                                <span className="user-tel">+{countryCode} {phoneNumber}</span>
                                {email != null ? <span className="user-mail">{email}</span> : null}
                            </div>) : null}

                            <div className="sidebar-links">
                                <Link className="link" to='/my-orders' onClick={closeProfileDrawer}>My Orders</Link>
                                <Link className="link" to='/wishlist' onClick={closeProfileDrawer}>My Wishlist</Link>
                                <Link className="link" to='/my-addresses' onClick={closeProfileDrawer}>My Address</Link>
                                {userType == "3" && <Link className="link" to='/purchase-history' onClick={closeProfileDrawer}>Purchase History</Link>}
                                {phoneNumber ? null : <a className="link" onClick={gotoSignin} >Sign Up/Sign In</a>}
                            </div>
                            <div className="sidebar-logout">
                                {phoneNumber ? <a className="logout-btn" onClick={logoutUser}>LOG OUT</a> : null}
                            </div>
                        </div>
                    </div>
                </span>
            </div>

        </>
    )
}
export default ProfileDrawer;