import React, { useEffect, useState } from "react";
import moment from "moment";
import ProductListModal from "../../components/ProductListModal"
import arrowIcon from '../../assets/icons/right-arrow-red.svg'


const PurchaseCard = ({ data, handleChangeInvoiceCard }) => {
    const [product, setProduct] = useState(null);
    const [showState, setShowState] = useState(false);

    const handleImageModal = (item) => {
        setShowState((showState) => showState = !showState)
        setProduct(item);
    }

    return (
        <>
            {showState && <ProductListModal product={product} show={showState} handleClose={handleImageModal}></ProductListModal>}

            {
                data?.length && data?.map((data) => {
                    return (
                        <div className="card purchase-card" onClick={() => handleChangeInvoiceCard(data)}>
                            <div>
                                <div className="card-row">
                                    <div className="name">{data.user.name}</div>
                                    <div className="amount">₹{data.orderTotal.toLocaleString()}</div>
                                </div>
                                <div className="card-row">
                                    {/* <div className="invoice">INVOICE {data.id}</div> */}
                                    <div className="date">{moment(data.updatedOn).format("DD/MM/YYYY")}</div>
                                </div>
                            </div>
                            {data?.items?.length &&
                                <div className="my-basket categories">
                                    <div className="category">
                                        <div className="category-title"></div>
                                        <div className="cart-items-wrap">


                                            <div className="card cart-item">
                                                <div className="item-image">
                                                    <img src={data.items[0]?.displayImage} alt="" />
                                                </div>
                                                <div className="item-body">
                                                    <div className="item-name">{data.items[0]?.name} {data.items.length > 1 ? `+ ${data.items.length - 1}` : ""}</div>
                                                    <div className="item-quantity">{data.items[0]?.price} x {data.items[0]?.units} = {data.items[0]?.price * data.items[0]?.units}</div>
                                                    <div className="item-spec">
                                                        Units: <strong>{data.items[0]?.units} Pack</strong> <strong>|</strong> Size: <strong>{data.items[0]?.size}</strong>
                                                    </div>
                                                    {data.items[0]?.deliveryPeriod.outOfStock ?
                                                        <div className="item-spec">
                                                            <span className="text-success">Estimated delivery in {data.items[0]?.inStock ? data.items[0]?.deliveryPeriod?.inStock : data.items[0]?.deliveryPeriod?.outOfStock} days</span>
                                                        </div> : null}

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="card-row">
                                <div>
                                    <div className="btn btn-status bg-primary text-white completed">{data.actualStatus}</div>
                                </div>
                                <a className="btn-go" onClick={()=>handleImageModal(data)}><img src={arrowIcon} alt="arrow" /></a>
                            </div>
                        </div>
                    )
                }
                )}


        </>
    )
}
export default PurchaseCard