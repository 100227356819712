import React from "react";
import './SubCategory.css'

const SubCategory = ({ selectSubCategoryClick, subCategoryData, slideLeft, slideRight ,title=""}) => {
        return (
                <section>
                        <div className="section-header">
                                <div className="section-title">{title}</div>
                        </div>
                        <div class="section-body sub-categories">
                                <div class="viewport">
                                        {subCategoryData && subCategoryData.length > 0 && subCategoryData.map((subCategory) => (
                                                <a class="card cat-card" onClick={() => selectSubCategoryClick(subCategory)}>
                                                        <img src={subCategory.image} alt="" />
                                                        <div class="card-content">
                                                                <span class="card-heading">{subCategory.displayName}</span>
                                                        </div>

                                                </a>))}
                                </div>
                                <button className="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
                                <button className="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button>
                        </div>
                </section>
        )
}
export default SubCategory;