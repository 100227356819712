import {useState,useEffect,useCallback } from 'react';

export const useProductListing = props =>{
    const [showFilterBar, setShowFilterBar] = useState(false);
    const [isSortCollapsed, setSortCollapsed] = useState(true);
    const [isPriceCollapsed, setPriceCollapsed] = useState(true);
    const [isBrandCollapsed, setBrandCollapsed] = useState(true);
    const [isReviewCollapsed, setReviewCollapsed] = useState(true);
    const [isDiscountCollapsed, setDiscountCollapsed] = useState(true);
    const [isOffersCollapsed, setOffersCollapsed] = useState(true);
    const [isMaterialCollapsed, setMaterialCollapsed] = useState(true);
    const handleCollapse = (id) => {
        switch(id){
            case 1 : setSortCollapsed(!isSortCollapsed) 
            break;
            case 2 : setPriceCollapsed(!isPriceCollapsed) 
            break;
            case 3 : setBrandCollapsed(!isBrandCollapsed) 
            break;
            case 4 : setReviewCollapsed(!isReviewCollapsed)
            break;
            case 5 : setDiscountCollapsed(!isDiscountCollapsed)
            break;
            case 6 : setOffersCollapsed(!isOffersCollapsed)
            break;
            case 7 : setMaterialCollapsed(!isMaterialCollapsed)
            break;
            default: break;
      }
       


    }
    return{
        showFilterBar,
        isSortCollapsed,
        isPriceCollapsed,
        isBrandCollapsed,
        isReviewCollapsed,
        isDiscountCollapsed,
        isOffersCollapsed,
        isMaterialCollapsed,
        setShowFilterBar,
        handleCollapse
    }
}