import CombineProducts from "./CombineProducts"
import DetailWrap from "./DetailWrap"
import ImageWrap from "./ImageWrap"
import RightContainer from "./RightContainer"
import ShareWrap from "./ShareWrap"
import SizesWrap from "./SizesWrap"
import Breadcrumbs from "../../components/Breadcrumbs"
import './ProductDetail.css'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from "react";
import { getProductById } from '../../redux/actions/product'
import { useParams } from 'react-router';
import DetailWrapModal from "./DetailWrapModal"

const ProductDetail = () => {
  const { id } = useParams();
  const { product } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    dispatch(getProductById(id))
  }, [id])

  if (!("cart_units" in product)) {
    product.cart_units = 0
  }
  // useEffect(() => {
  //   if(!"cart_units" in product)
  //   product.cart_units = 0
  // }, [product])
  const handleShowDetails = () => {
    setShowDetails(true)
  }
  const handleCloseShowDetails = () => {
    setShowDetails(false)
  }
  let breadCrumbTabs = ["Categories"]
  breadCrumbTabs.push(product && product.categoryName)
  breadCrumbTabs.push(product && product.title)
  return (
    <div className="content">
      <Breadcrumbs className="breadcrumbs" tabs={breadCrumbTabs} />
      <div className="row item-details flex-lg-row">
        <div className="col col-lg-8">
          <div className="item-left">
            <ImageWrap product={product} />
            <hr />
            <SizesWrap product={product} />
            {
              product.combinedProducts &&
              <>
                <hr />
                <CombineProducts data={product} />
              </>
            }
            <hr />
            {showDetails ? (<DetailWrapModal product={product} showDetails={showDetails} handleCloseShowDetails={handleCloseShowDetails} />) : null}
            {/* <DetailWrap product={product} /> */}
            {/* <hr /> */}
            <ShareWrap />
          </div>
        </div>
        <div className="col col-lg-4">
          <RightContainer data={product} handleShowDetails={handleShowDetails} />
        </div>
      </div></div>
  )
}
export default ProductDetail