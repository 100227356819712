import {useState,useEffect,useCallback } from 'react';
export const useHome = props =>{
 const slideRight = (e) => {
    var container = e.target.closest("div").querySelector('.viewport');
    container.scrollBy({
        top: 0,
        left: +600,
        behavior: 'smooth'
    });
};

const slideLeft =(e) => {
    var container = e.target.closest("div").querySelector('.viewport');
    container.scrollBy({
        top: 0,
        left: -600,
        behavior: 'smooth'
    });
};

  return{
    slideRight,
    slideLeft
  }
}