const ShareWrap =() =>{
   return(
    <div className="share-wrap">
    <div className="share-text">
        <div>Like this Product?</div>
        <div>Share it with your friends an family</div>
    </div>
    <div className="share-btn btn">
        <div className="text">SHARE</div>
        <img src="assets/icons/share-icon.svg" alt="" className="img" />
    </div>
</div>
   )
}
export default ShareWrap