import React from "react";
const ProductSubCategoryList = ({openProductSubCategories,subCategories,selectedCategory,handleSubCategoryClick}) =>{
   
    return(
        <div class="menu-right">
        <div class="menu-links">
            <a class="link">{selectedCategory}</a>
            <div class="sublink-wrap">
                {subCategories && subCategories.length>0 && subCategories.map((sub_category)=>(
                     <a onClick={()=>handleSubCategoryClick(sub_category)} class="sublink">{sub_category.displayName}</a>
                ))}
            </div>
        </div>
    </div>
    )
}
export default ProductSubCategoryList