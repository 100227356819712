import React, { useEffect, useState } from "react";
const Payment = ({handlePayMinimum,handlePayOther,amountTobePaid,isMinimumSelected,setAmountTobePaid,cartValue}) => {
    // useEffect(() => {
    //     setAmountTobePaid(cartValue * 25/100)
    //  }, [])
  
    return (
        <>
          <div class="col-12 col-lg-7 payment-details-wrap">
                <div class="payment-details">
                    
                    <div class="payment-option">
                        <label class="option-wrap">Pay Minimum (25% of Grand Total)
                            <input type="radio" name="option" value="0" onChange={(e)=>handlePayMinimum(e.target.value)} checked={isMinimumSelected == "0"} />
                            <div class="custom-radio"></div>
                        </label>
                        <label class="option-wrap">Pay Other Amount (&gt;25%)
                            <input type="radio" name="option" value="1" onChange={(e)=> handlePayOther(e.target.value)} checked={isMinimumSelected == "1"} />
                            <div class="custom-radio"></div>
                        </label>
                    </div>
                    <div class="payment-other">
                        <div class="other-title">
                            Enter Other Amount
                        </div>
                        <input type="text" name="other" id="other-amount" value={amountTobePaid} placeholder="Enter amount in Indian Rupees" 
                        onChange={(e)=>setAmountTobePaid(e.target.value)} readOnly={isMinimumSelected == "0"}
                        />
                    </div>
                    
                    <hr/>
                </div>
                
            </div>
        </>
    )
}
export default Payment;
