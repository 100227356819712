import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { fetchCategories, fetchSubCategories } from '../../redux/actions/home'
import subCategoryImg from '../../assets/images/sub-feature.png'
import './Category.css';
import { useHome } from '../../logics/Home/useHome'
import SubCategory from "../../components/SubCategory";


const Category = () => {

    const logicProps = useHome()
    const { slideRight, slideLeft } = logicProps

    const dispatch = useDispatch()
    const history = useHistory();
    const { categoriesData, subCategoryData, categoryDetails } = useSelector((state) => state.home);
    const [activeCategory, setActiveCategory] = useState('');
    useEffect(() => {
        if (categoriesData.length == 0)
            dispatch(fetchCategories())
    }, [])

    useEffect(() => {
        if (categoriesData && categoriesData.length > 0) {
            setActiveCategory(categoriesData[0])
            dispatch(fetchSubCategories(categoriesData[0].id))
        }

    }, [categoriesData])

    useEffect(() => {
        if (categoryDetails) {
            setActiveCategory(categoryDetails)
            dispatch(fetchSubCategories(categoryDetails.id))
        }

    }, [categoryDetails])

    const selectCategory = (category) => {
        setActiveCategory(category)
        dispatch(fetchSubCategories(category.id))
    }
    const selectSubCategoryClick = useCallback((sub_category) => {
        // setShowNotificationDrawer(!showNotificationDrawer)
        let path = `/product-listing/${activeCategory.displayName}+${sub_category.parentId}/${sub_category.displayName}+${sub_category.id}`
        // setShowMenuDrawer(false)
        history.push(path);

    }, [activeCategory])
    return (
        <div class="categories-wrap">
            <div class="categories-top">
                <div class="viewport">
                    {categoriesData && categoriesData.length > 0 && categoriesData.map((category) => (
                        <a class={`card cat-card ${activeCategory && activeCategory.id == category.id ? "active" : ''}`} onClick={() => selectCategory(category)}>
                            <img src={category.image} alt="" />
                            <div class="card-content">
                                <span class="card-heading">{category.displayName}</span>
                            </div>
                        </a>
                    ))}
                </div>
                <button class="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
                <button class="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button>
            </div>
            <div class="category-body">
                <div class="featured">
                    <a href="#" class="card featured-card">
                        <img src={subCategoryImg} alt="" />
                        <div class="card-content">
                            <span class="card-heading">{activeCategory && activeCategory.displayName}</span>
                        </div>
                    </a>
                </div>
                {/* <div class="sub-categories">
                    <div class="viewport"> */}
                        {/* {subCategoryData && subCategoryData.length > 0 && subCategoryData.map((subCategory) => ( */}
                            {/* // <a class="card cat-card" onClick={() => selectSubCategoryClick(subCategory)}>
                            //     <img src={subCategory.image} alt="" />
                            //     <div class="card-content">
                            //         <span class="card-heading">{subCategory.displayName}</span>
                            //     </div>

                            // </a> */}
                            <SubCategory selectSubCategoryClick={selectSubCategoryClick} subCategoryData={subCategoryData} slideLeft={slideLeft} slideRight={slideRight} />
                        {/* ))} */}
                    {/* </div>

                    <button class="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
                    <button class="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button>
                </div> */}

            </div>
        </div>
    )

}
export default Category