import axios from "axios";
import { getUserToken } from "../logics/User/userData";
import cryptoJS from "crypto-js";
const PRODUCTION = process.env.REACT_APP_PRODUCTION;
const APIURL =
  PRODUCTION === "true"
    ? process.env.REACT_APP_APIURL_LIVE
    : process.env.REACT_APP_STAGING_APIURL;

const tileEcomapi = axios.create({
  baseURL: APIURL,
});


tileEcomapi.interceptors.request.use(
  (config) => {
    console.log(config);
    const clientId = "web-client";
    const clientSecret = "web-client-secret";
    const contentType = "application/json";
    const headerDate = new Date().toISOString();
    const method = config.method.toUpperCase();
    const baseURL = config.url.replace(tileEcomapi.defaults.baseURL, "");
    const reqPath = "/api/v2/" + baseURL;

    let body;
    if (method === "POST" || method === "PUT" || method === "DELETE") {
      body = config.data ? JSON.stringify(config.data) : "";
    } else {
      body = "";
    }
    const md5hash = cryptoJS.MD5(body || "");
    const headerMD5 = cryptoJS.enc.Base64.stringify(md5hash);
    const hmac = cryptoJS.algo.HMAC.create(cryptoJS.algo.SHA256, clientSecret);
    hmac.update(method);
    hmac.update(reqPath);
    hmac.update(contentType);
    hmac.update(headerDate);
    hmac.update(headerMD5);
    hmac.update(clientId);
    const hmacHash = hmac.finalize();
    const headerAuth = cryptoJS.enc.Base64.stringify(hmacHash);

    config.headers["x-authorization-timestamp"] = headerDate;
    config.headers["x-authorization-clientid"] = clientId;
    config.headers["x-authorization-content-md5"] = headerMD5;
    config.headers["authorization"] = headerAuth;
    config.headers["Content-Type"] = contentType;

    const accessToken = getUserToken();

    if (accessToken) {
      config.headers["x-warehouse-token"] = `${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default tileEcomapi;
