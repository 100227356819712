import {
    GET_PRODUCT_BY_ID,
    ADD_TO_CART,
    GET_CART,
    DELETE_CART,
    INSERT_TO_CART,
    GET_CART_COUNT,
    REMOVE_CART_COUNT,
    INITIATE_ORDER,
    GET_MY_ORDERS,
    RAZORPAY_ORDER
} from "./types";
import tileEcomapi from "../../apis";

                                                                                                                                             
export const getProductById = (id) => (dispatch) => {
    tileEcomapi
        .get(`products/${id}`)
        .then((resp) => {
            dispatch({
                type: GET_PRODUCT_BY_ID,
                payload: resp.data.response,
                status: resp.status,
            });
            dispatch(pushToCartList([]));
        })
        .catch((error) => { });
};

export const getCart = () => (dispatch) => {
    tileEcomapi
        .get(`cart`)
        .then((resp) => {
            dispatch({
                type: GET_CART,
                payload: resp.data.response,
                status: resp.status,
            });
            dispatch({
                type: ADD_TO_CART,
                status: false,
            });
            dispatch(getCartCount());
        })
        .catch((error) => { });
};
export const getCartCount = () => (dispatch) => {
    tileEcomapi
        .get(`cart/product-count`)
        .then((resp) => {
            dispatch({
                type: GET_CART_COUNT,
                payload: resp.data.response,
                status: resp.status,
            });
        })
        .catch((error) => { });
};

export const removeCartCount = () => (dispatch) => {
            dispatch({
                type: REMOVE_CART_COUNT,
            });
       
};

export const addToCart = (data, productId) => (dispatch) => {
    tileEcomapi
        .post(`cart`, data)
        .then((resp) => {
            if (resp.status == 200) {
                dispatch({
                    type: ADD_TO_CART,
                    payload: resp.data.response,
                    status: resp.data.status,
                });
                dispatch(getProductById(productId));
            }
        })
        .catch((error) => { });
};
export const deleteCart = (data,location) => (dispatch) => {
    
    tileEcomapi
        .delete(`cart`, { data })
        .then((resp) => {
            dispatch({
                type: DELETE_CART,
                payload: resp.data.response,
                status: resp.status,
            });
            if(location.pathname == "/cart") dispatch(getCart())
            // dispatch(getCart());
        })
        .catch((error) => { });
};

export const initiateOrder = (data) => (dispatch) => {
    tileEcomapi
        .post(`order/initiate-order`, data)
        .then((resp) => {
            console.log(resp);
            dispatch({
                type: INITIATE_ORDER,
                payload: resp.data.response,
                status:resp.data.status
            })
        })
        .catch((error) => { });
};

export const pushToCartList = (data) => (dispatch) => {
    dispatch({
        type: INSERT_TO_CART,
        payload: data,
    });
};

export const getOrderList = (id, type, status="") => (dispatch) => {
    const data = {
        isCommissionOrder: id,
        orderType: type,
        status: status,
    };
    Object.keys(data).forEach(key => data[key] === "" && delete data[key])

    tileEcomapi.get(`order/order-list`, { params: data })
        .then((resp) => {
            dispatch({
                type: GET_MY_ORDERS,
                payload: resp.data.response,
                status: resp.status,
            });
        })
        .catch((error) => { });
};

export const razorpayOrder = (data) => (dispatch) => {
    tileEcomapi
        .post(`/webhooks/order-payment-razorpay`, data)
        .then((resp) => {
            console.log(resp);
            dispatch({
                type: RAZORPAY_ORDER,
                payload: resp.data,
            })
        })
        .catch((error) => { });
};
