import React, { useEffect, useState } from "react";
import itemImage from "../../assets/images/cat-1.png";
import eyeIcon from '../../assets/icons/eye-icon.svg'
import Modal from 'react-bootstrap/Modal'
import ImageModal from "../../components/ImageModal"
import { useDispatch, useSelector } from 'react-redux'
import { pushToCartList } from "../../redux/actions/product";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const StyleElements = ({ data }) => {
    const product = data;
    const { combinedProducts } = product || [];
    const { productQtyList } = useSelector((state) => state.product);
    const dispatch = useDispatch()

    const [show, setShow] = useState(false);
    const [showState, setShowState] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [productList, setProductQuantity] = useState([]);
    const [image, setImage] = useState(null);
    const [lableName, setLableName] = useState('Quantity');


    const handleOptionChange = (e, item) => {
        setLableName(e.target.value)
        item.label = e.target.value;
        item.units = 0
        item.area = 0
        setProductQuantity([...productList]);
    }

    const handleChange = (e, item) => {
        if (lableName == 'Quantity')
            item.units = e.target.value;
        else {
            item.area = e.target.value;
            item.units = Math.floor((e.target.value - product?.coverArea) / product?.coverArea)
        }

        addToCartList(item)
        setProductQuantity([...productList]);
    }

    const handleUnitMinus = (item) => {
        if (item.units == 0) return
        item.units--;
        addToCartList(item)
        setProductQuantity([...productList]);
    }

    const handleUnitPlus = (item) => {
        if (item.units == undefined) item.units = 0
        item.units++;
        addToCartList(item)
        setProductQuantity([...productList]);
    }

    const handleAreaMinus = (item) => {
        if (item.area == 0) return
        item.area--;
        item.units = Math.floor((item.area - product?.coverArea) / product?.coverArea)
        addToCartList(item)
        setProductQuantity([...productList]);
        // setcartData({ area: cartData.area - product?.coverArea, units: Math.ceil((cartData.area - product?.coverArea) / product?.coverArea) })
    }
    const handleAreaPlus = (item) => {
        item.area++;
        item.units = Math.floor((item.area + parseFloat(product?.coverArea)) / product?.coverArea)
        addToCartList(item)
        setProductQuantity([...productList]);
        // setcartData({ area: parseFloat(cartData.area) + parseFloat(product?.coverArea), units: Math.ceil((parseFloat(cartData.area) + parseFloat(product?.coverArea)) / product?.coverArea) })

    }

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (data) => {
        setShow(true);
        setModalData(data);
    };

    const handleImageModal = (itemImage) => {
        setShowState((showState) => showState = !showState)
        setImage(itemImage);
    }

    // const appendToCartList = (product) => {
    //     const i = productQtyList.findIndex(_item => _item.productId === product.productId);
    //     if (i > -1) productQtyList[i] = product;
    //     else productQtyList.push(product);
    //     let result = productQtyList.map((_item) => {
    // const initProducts = productList.find(_item => {if(_item.productId === product.productId){
    //     return _item.units
    // }});
    // const initProducts = productList.find(_item => _item.productId === product.productId);
    // const finalCartUnits = setCartUnits(initProducts,_item.units,);
    //     })

    //     dispatch(pushToCartList(result))

    // };



    const addToCartList = (item) => {
        // if (item.units == 0) return
        const i = productQtyList.findIndex(_item => _item.productId === item.productId);
        if (i > -1) productQtyList[i] = item;
        else productQtyList.push(item);
        let result = productQtyList.map(({ productId, units }) => ({ productId, units }));
        const initProductUnits = combinedProducts.find(_item => _item.productId === item.productId).cart_units;
        const finalCartUnits = productQtyList.find(_item => _item.productId === item.productId).units;
        const resultUnits = setCartUnits(initProductUnits, finalCartUnits);
        const objIndex = result.findIndex((obj => obj.productId == item.productId));
        result[objIndex].units = resultUnits;
        dispatch(pushToCartList(result))
    };

    const setCartUnits = (initUnit, finalUnit) => {
        if (finalUnit === 0) return 0;
        return finalUnit - initUnit;
    }

    useEffect(() => {
        let product = combinedProducts?.map(item => { item.productId = item.productId; item.units = item.cart_units; item.label = "Quantity"; return item });
        setProductQuantity(product);
    }, [combinedProducts]);

    return (
        <>
            {
                productList?.map((item, i) => {
                    return (
                        <div className="style" key={i}>
                            <div className="style-title">{item.title}</div>
                            <div className="style-body d-flex align-items-end">
                                <img className="cursor" src={itemImage} alt="" onClick={() => handleImageModal(itemImage)} />
                                <div className="style-select">
                                    <select onChange={(e) => handleOptionChange(e, item)} name="label">
                                        <option value="Quantity">Qty</option>
                                        <option value="Area">Area</option>
                                    </select>
                                </div>
                                <div className="value">
                                    <div className="value-title">{lableName}</div>
                                    <div className="value-body input-group">
                                        <input className="btn"
                                            onClick={lableName == 'Quantity' ? () => handleUnitMinus(item) : () => handleAreaMinus(item)} type="button" value="-" />
                                        <input type="text" onChange={(e) => handleChange(e, item)}
                                            value={lableName == 'Quantity' ? item.units : item.area} name="units" id="" placeholder="0" />
                                        <input className="btn" onClick={lableName == 'Quantity' ? () => handleUnitPlus(item) :
                                            () => handleAreaPlus(item)} type="button" value="+" />
                                    </div>
                                </div>
                            </div>
                            <div class="style-body  body-bottom d-flex justify-content-evenly my-3">
                                <div class="pricing">
                                    <div class="price-wrap d-flex mb-0">
                                        <div class="price">
                                            ₹ {item.price} <div>Per Piece</div>
                                        </div>
                                        <div class="price-old">₹ {item.mrp}</div>
                                        <div class="percentage-off">{~~(((item.mrp - item.price) / item.mrp) * 100)}% OFF</div>
                                    </div>
                                    <div class="tax-info">(*Inclusive of all Taxes)</div>
                                </div>
                                <div class="d-flex align-items-center" >
                                    <button type="button" class="btn view-btn" onClick={() => handleShow(item)}><img src={eyeIcon} alt="eye" /></button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <Modal show={show} onHide={handleClose} size="lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" onClick={handleClose} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body item-details">
                        <Slide>
                            {modalData?.productImages?.map((slideImage, index) => (
                                <div className="each-slide" key={index}>
                                    <img class="img-fluid image-small" src={slideImage} alt="" />
                                </div>
                            ))}
                        </Slide>
                        <div class="item-right mt-2">
                            <div class="product-name pb-2">{modalData?.title}</div>
                            <div class="price-wrap d-flex">
                                <div class="price">
                                    ₹ {modalData?.price} <div>Per Piece</div>
                                </div>
                                <div class="price-old">₹ {modalData?.mrp}</div>
                                <div class="percentage-off">{Math.floor(((modalData?.mrp - modalData?.price) / modalData?.mrp) * 100)}% OFF</div>
                            </div>
                            <div class="tax-info">(*Inclusive of all Taxes)</div>
                            <div class="stock-info">{modalData?.inStock == 1 && "IN STOCK"}</div>
                        </div>
                        <hr />
                        <div class="details-wrap">
                            <div class="title">Details</div>
                            <div class="detail-table row">
                                <div className="col">
                                    <div className="detail d-flex my-3">
                                        <div className="key">Price</div>
                                        <div className="value">{modalData.price} Per Piece</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Size</div>
                                        <div className="value">{modalData.size}</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Ideal For</div>
                                        <div className="value">{modalData.idealFor}</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Quality</div>
                                        <div className="value">{modalData.quality}</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Material Type</div>
                                        <div className="value">{modalData.materialType}</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Finish</div>
                                        <div className="value">{modalData.finish}</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="detail d-flex my-3">
                                        <div className="key">Brand</div>
                                        <div className="value">{modalData.brand}</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Thickness</div>
                                        <div className="value">{modalData.thickness}</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Rate Per Sqft</div>
                                        <div className="value">{modalData.ratePerSquareFeet}</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Cover Area</div>
                                        <div className="value">{modalData.coverArea} Sq.Ft.</div>
                                    </div>
                                    <div className="detail d-flex my-3">
                                        <div className="key">Weight Per Piece</div>
                                        <div className="value">{modalData.weight} Kg</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {showState && <ImageModal image={image} show={showState} handleClose={handleImageModal} />}

        </>



    )
}
export default StyleElements