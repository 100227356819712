import React,{useEffect} from "react";
import './WishList.css'
import Breadcrumbs from "../../components/Breadcrumbs";
import CardProduct from '../ProductListing/CardProduct';
import {getWishlist,refreshWishlistStatus,removeItemFromWishlist} from "../../redux/actions/wishlist"
import { useDispatch,useSelector } from 'react-redux'
const WishList = () =>{
  let breadCrumbTabs = ["Wishlist"]
  const dispatch = useDispatch()
  const { wishList,itemAddedToWishlist,itemRemovedFromWishlist} = useSelector((state) => state.wishlist);
  useEffect(()=>{
    dispatch(getWishlist())
},[])
const handleChangeWishlist = (product) => {
    const body ={
      productIds:[product.productId]
    }
    dispatch(removeItemFromWishlist(body))
}
useEffect(()=>{
    dispatch(getWishlist()) 
    dispatch(refreshWishlistStatus())
  
},[itemAddedToWishlist,itemRemovedFromWishlist])
return(
    <div class="wishlist-wrap">
         <Breadcrumbs tabs={breadCrumbTabs} />
         <div class="products">
         {wishList && wishList.items && wishList.items.length >0 && 
        wishList.items.map((wishitem)=>(
        <CardProduct cardProductDetail={wishitem} handleChangeWishlist={handleChangeWishlist}/>
        ))}
      </div>
    </div>
)
}
export default WishList