import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchAddress } from "../../redux/actions/home";


import Breadcrumbs from "../../components/Breadcrumbs"
import AddressCard from "./AddressCard"
import plusIcon from '../../assets/icons/plus-icon-blue.svg'
import { Link } from 'react-router-dom';
import './MyAddress.css'
const MyAddress = () => {
    let breadCrumbTabs = ["My Addresses"]
    const { addressList: { addresses } } = useSelector((state) => state.home);
    const [defaultAddress, setDefaultAddress] = useState(localStorage.getItem("defaultAddressId")?localStorage.getItem("defaultAddressId"):"")
    const handleStoreDefaultStore =(id) =>{
        localStorage.setItem("defaultAddressId",id)
        setDefaultAddress(id)
    }
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAddress())
    }, [])
    return (
        <div className="addresses-wrap">
            <Breadcrumbs tabs={breadCrumbTabs} />
            <Link to='/add-address' className="btn add-address"> <img src={plusIcon} alt="" />
                <span>Add New Address</span>
            </Link>
            <div className="address-cards-wrap d-flex">
                {addresses?.map((item) => {
                    return (
                        <AddressCard address={item} handleStoreDefaultStore={handleStoreDefaultStore} defaultAddress={defaultAddress} />
                    )
                })}
            </div>
        </div>
    )
}
export default MyAddress