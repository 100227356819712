import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom';
import menuIcon from '../../assets/icons/header-menu.svg'
import logoIcon from '../../assets/logo/logo-full-2x.png'
import notifyIcon from '../../assets/icons/notification-icon.svg'
import wishlistIcon from '../../assets/icons/wishlist-icon.svg'
import cartIcon from '../../assets/icons/cart-icon.svg'
import profileIcon from '../../assets/images/profile-image.jpg'
import ProfileDrawer from "../../components/ProfileDrawer"
import NotificationDrawer from "../NotificationDrawer";
import MenuDrawer from "../../components/MenuDrawer"
import { useHeader } from "../../logics/Header/useHeader"
import EditProfile from "../ProfileDrawer/EditProfile";
import { getCartCount } from "../../redux/actions/product";


const Header = ({ getUserData,openSigninModal }) => {
    const logicProps = useHeader();
    const userData = getUserData()
    const dispatch = useDispatch()


    const {
        openDrawer,
        openMenuDrawer,
        openNotificationDrawer,
        openProductCategories,
        openProductSubCategories,
        EditProfileView,
        handleSubCategoryClick,
        showMenuDrawer,
        showDrawer,
        showNotificationDrawer,
        showProductCategories,
        showProductSubCategories,
        editView,
        selectedCategory,
    } = logicProps;
    const { cartCount } = useSelector((state) => state.product);
    useEffect(() => {
        dispatch(getCartCount())
        console.log(userData);
    }, [])

    const handleNotification = () => {
        if (!userData.id) {
            openSigninModal(true);
            return
        }
        openNotificationDrawer();
    }

    return (

        <>
            <header className="navbar" >
                <div className="menu-wrap">
                    <img src={menuIcon} alt="Menu icon" onClick={openMenuDrawer} />
                </div>
                <div className="logo-wrap">
                    <a href="/">
                        <img src={logoIcon} alt="Logo icon" />
                    </a>
                </div>
                <div className="search-wrap">
                    <input type="text" name="searchbar" placeholder="Search for the materials" id="searchbar" />
                </div>
                <div className="profile-wrap">
                    <img src={notifyIcon} alt="Notification icon" onClick={handleNotification} />
                    <Link to='/wishlist'> <img src={wishlistIcon} alt="Wishlist icon" /></Link>
                    <div class="icon-wrapper">
                        <Link to='/cart'> <img src={cartIcon} alt="Cart icon" /></Link>

                        <span class="badge">{cartCount.count}</span>
                    </div>
                    <img src={userData.profilePicture || profileIcon} alt="Profile icon" id="profile-icon" onClick={openDrawer} />
                </div>

            </header>
            {showDrawer ? (editView ? <EditProfile backtoUserProfile={EditProfileView} saveProfileDetails={EditProfileView} /> : <ProfileDrawer closeProfileDrawer={openDrawer} EditProfileView={EditProfileView} openSigninModal={openSigninModal} />) : null}
            {showMenuDrawer ? (<MenuDrawer
                openProductCategories={openProductCategories}
                openProductSubCategories={openProductSubCategories}
                showProductCategories={showProductCategories}
                showProductSubCategories={showProductSubCategories}
                closeMenu={openMenuDrawer}
                selectedCategory={selectedCategory}
                handleSubCategoryClick={handleSubCategoryClick}
            />) : null}
            {(showNotificationDrawer && userData.id) ? (<NotificationDrawer closeNotificationDrawer={openNotificationDrawer} />) : null}
        </>
    )
}
export default Header;
