import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect } from "react";
import { pushToCartList } from "../../redux/actions/product";
import { useHistory } from 'react-router-dom';
import { addItemtoWishlist, removeItemFromWishlist, getWishlist, refreshWishlistStatus } from "../../redux/actions/wishlist"
import { addToCart, deleteCart } from "../../redux/actions/product";
import { useParams } from 'react-router';
import eyeIcon from '../../assets/icons/eye-icon.svg'


const RightContainer = ({ data, handleShowDetails }) => {
    let history = useHistory();
    const { id } = useParams();

    const product = data;
    const { addToCartstatus, productQtyList } = useSelector((state) => state.product);
    const [cartData, setcartData] = useState({ units: product?.cart_units || 0, area: product?.cart_units == undefined ? 0 : product?.coverArea * product?.cart_units })
    const dispatch = useDispatch()
    const [projectSelected, setProjectSelected] = useState({});
    const { itemAddedToWishlist, itemRemovedFromWishlist } = useSelector((state) => state.wishlist);

    const goToLink = () => {
        history.push('/cart');
    }

    const handleChangeWishlist = (product) => {
        setProjectSelected(product)
        const body = {
            productIds: [product.productId]
        }
        if (product.isWishListed) {

            dispatch(removeItemFromWishlist(body))
        }
        else
            dispatch(addItemtoWishlist(body))
    }

    const handleBlur = () => {
        setcartData({ ...cartData, "area": cartData.units * product?.coverArea })
    }

    const handleChange = (e) => {
        setcartData({ ...cartData, [e.target.name]: e.target.value })
        if (e.target.name == "area")
            setcartData({ [e.target.name]: e.target.value, units: Math.ceil(e.target.value / product?.coverArea) })
        if (e.target.name == "units")
            setcartData({ [e.target.name]: e.target.value, area: e.target.value * product?.coverArea })
    }
    const handleAreaMinus = () => {
        setcartData({ area: cartData.area - product?.coverArea, units: Math.ceil((cartData.area - product?.coverArea) / product?.coverArea) })
    }
    const handleAreaPlus = () => {
        setcartData({ area: parseFloat(cartData.area) + parseFloat(product?.coverArea), units: Math.ceil((parseFloat(cartData.area) + parseFloat(product?.coverArea)) / product?.coverArea) })

    }
    const handleUnitMinus = () => {
        setcartData({ units: parseInt(cartData.units) - 1, area: (cartData.units - 1) * product?.coverArea })
    }
    const handleUnitPlus = () => {
        if (cartData.units == undefined) cartData.units = 0
        setcartData({ units: parseInt(cartData.units) + 1, area: (cartData.units + 1) * product?.coverArea })
    }
    const appendToCartList = () => {
        const finalCartUnits = setCartUnits(product.cart_units, cartData.units);
        if (finalCartUnits != 0) {
            const obj = {
                productId: product.productId,
                units: finalCartUnits
            }
            const i = productQtyList.findIndex(_item => _item.productId === obj.productId);
            if (i > -1) productQtyList[i] = obj;
            else productQtyList.push(obj);
            let result = productQtyList.map(({ productId, units }) => ({ productId: productId, units }))
            dispatch(pushToCartList(result))
        };
    }

    const setCartUnits = (initUnit, finalUnit) => {
        return finalUnit - initUnit;
    }


    const onAddToCart = () => {
        appendToCartList()
        productQtyList.forEach((el) => { el.productId = el.productId.toString() })
        const removeList = productQtyList.filter(el => el.units <= 0);
        const addList = productQtyList.filter(el => el.units > 0);
        if (removeList?.length) {
            removeList.forEach((el) => { el.units = Math.abs(el.units) })
            dispatch(deleteCart(removeList, id));
        }
        if (addList?.length) {
            addList.forEach((el) => { el.units = Math.abs(el.units) })
            dispatch(addToCart(addList, id));
        }
    }

    useEffect(() => {
        setcartData({ units: product?.cart_units, area: product?.cart_units == undefined ? 0 : product?.coverArea * product?.cart_units })
        if (itemAddedToWishlist || itemRemovedFromWishlist) {
            projectSelected.isWishListed ? projectSelected.isWishListed = 0 : projectSelected.isWishListed = 1
            dispatch(refreshWishlistStatus())
        }

    }, [data, itemAddedToWishlist, itemRemovedFromWishlist]);

    return (
        <div className="item-right sticky-top">
            <div className="product-name">{product?.title}</div>
            <div className="product-cat">{product?.description}</div>
            <hr />
            <div className="price-wrap d-flex">
                <div className="price">
                    ₹ {product.price} <div>Per Piece</div>
                </div>
                <div className="price-old">₹ {product.mrp}</div>
                <div className="percentage-off">{product.discount || 0}% OFF</div>

            </div>
            {product.isTileOrGranite ? (<div className="price-wrap d-flex"><div className="sqfeet">
                ₹ {product.ratePerSquareFeet} <span>Per Square feet</span>
            </div></div>) : null}

            <div class="d-flex align-items-center" >
                <div className="tax-info">(*Inclusive of all Taxes)</div>
                <button type="button" class="btn view-btn1" ><img src={eyeIcon} onClick={() => handleShowDetails()} alt="eye" /></button>
            </div>

            <div className="stock-info">IN STOCK</div>
            <div className="row value-wrap my-2">
                <div className="col value">
                    <div className="value-title">Quantity</div>
                    <div className="value-body input-group">
                        <input className="btn" type="button" value="-" onClick={handleUnitMinus} />
                        <input type="text" value={cartData.units} name="units" id="units" onChange={handleChange} />
                        <input className="btn" type="button" value="+" onClick={handleUnitPlus} />
                    </div>
                </div>
                {
                    product.combinedProducts &&
                    <>
                        <div className="col-2">OR</div>
                        <div className="col value">
                            <div className="value-title">Area (Sq.Ft.)</div>
                            <div className="value-body input-group">
                                <input className="btn" type="button" value="-" onClick={handleAreaMinus} />
                                <input type="text" value={cartData.area} name="area" id="area" onChange={handleChange} onBlur={handleBlur} />
                                <input className="btn" type="button" value="+" onClick={handleAreaPlus} />
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className="delivery-info">Estimated Delivery in {product?.deliveryPeriod?.inStock} Days</div>
            <hr />
            <div className="buttons-wrap">
                <a onClick={() => handleChangeWishlist(product)}>
                    <i className={`fa fa-heart fa-2x bg-primary btn btn-icon  ${product?.isWishListed ? 'text-danger' : 'text-white'}`} aria-hidden="true"></i>
                </a>

                {(product?.cart_units === 0 || !product?.cart_units) ?
                    <a onClick={onAddToCart} className="add-to-basket"> <span>Add to Basket</span></a> :
                    ((product?.cart_units > 0) && (!addToCartstatus)) ?
                        <a onClick={onAddToCart} className="add-to-basket"> <span>Update Basket</span></a> :
                        <a onClick={goToLink} className="add-to-basket"> <span>Go to Basket</span></a>
                }

                <div className="rating-wrap">
                    {/* <div className="rating">
                        <div className="value">4.1</div>
                        <div className="stars">
                            <img src="assets/icons/star-1.svg" alt="" />
                            <img src="assets/icons/star-1.svg" alt="" />
                            <img src="assets/icons/star-1.svg" alt="" />
                            <img src="assets/icons/star-1.svg" alt="" />
                            <img src="assets/icons/star-0.svg" alt="" />
                        </div>
                    </div>
                    <div className="count">209 Ratings</div> */}
                </div>
            </div>
        </div>
    )
}
export default RightContainer