export const FETCH_HOME_TOP_BANNER = 'FETCH_HOME_TOP_BANNER'; 
export const FETCH_HOME_BANNER = 'FETCH_HOME_BANNER'; 
export const FETCH_OFFER_BANNER = 'FETCH_OFFER_BANNER';
export const FETCH_PRODUCT_BANNER = 'FETCH_PRODUCT_BANNER';
export const FETCH_BOTTOM_BANNER = 'FETCH_BOTTOM_BANNER';
export const FETCH_OFFERS = 'FETCH_OFFERS'; 
export const FETCH_TILE_OFFERS = 'FETCH_TILE_OFFERS'
export const FETCH_NON_TILE_OFFERS = 'FETCH_NON_TILE_OFFERS'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'; 
export const FETCH_POPULAR_CATEGORIES = 'FETCH_POPULAR_CATEGORIES';
export const FETCH_SUB_CATEGORIES = 'FETCH_SUB_CATEGORIES'; 
export const FETCH_HOTLOOKS = 'FETCH_HOTLOOKS';
export const FETCH_POPULAR_PRODUCTS = 'FETCH_POPULAR_PRODUCTS';
export const FETCH_NEW_ARRIVAL_PRODUCTS = 'FETCH_NEW_ARRIVAL_PRODUCTS'
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_OTP_VERIFY = 'SIGNUP_USER_OTP_VERIFY'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_OTP_VERIFY = 'LOGIN_USER_OTP_VERIFY'
export const RESET_USER = 'RESET_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const FETCH_USER_DATA = 'FETCH_USER_DATA'
export const SELECT_CATEGORY_ID = 'SELECT_CATEGORY_ID'
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const GET_SIZES_LIST = 'GET_SIZES_LIST'
export const GET_CAPTCHA = 'GET_CAPTCHA'


export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID'

export const GET_WISH_LIST = 'GET_WISH_LIST'
export const ADD_ITEM_TO_WISHLIST = 'ADD_ITEM_TO_WISHLIST'
export const REMOVE_ITEM_FROM_WISHLIST = 'REMOVE_ITEM_FROM_WISHLIST'
export const REFRESH_WISHLIST_STATUS = 'REFRESH_WISHLIST_STATUS'

export const GET_CART = 'GET_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const DELETE_CART= 'DELETE_CART'
export const INSERT_TO_CART = 'INSERT_TO_CART'
export const GET_CART_COUNT= 'GET_CART_COUNT'
export const REMOVE_CART_COUNT= 'REMOVE_CART_COUNT'
export const INITIATE_ORDER= 'INITIATE_ORDER'
export const RAZORPAY_ORDER= 'RAZORPAY_ORDER'



export const FETCH_NOTIFICATIONS= 'FETCH_NOTIFICATIONS'
export const FETCH_ADDRESS= 'FETCH_ADDRESS'
export const ADD_ADDRESS= 'ADD_ADDRESS'
export const DELETE_ADDRESS= 'DELETE_ADDRESS'
export const UPDATE_ADDRESS= 'UPDATE_ADDRESS' 
export const FETCH_START_POINT= 'FETCH_START_POINT'
export const GET_DISTANCE= 'GET_DISTANCE'
export const CALCULATE_SHIPPING_CHARGE= 'CALCULATE_SHIPPING_CHARGE'
export const GET_PURCHASE_HISTORY = 'GET_PURCHASE_HISTORY'
export const GET_MY_ORDERS = 'GET_MY_ORDERS'
export const UPLOAD_ORDER = 'UPLOAD_ORDER'
export const VERIFY_CAPTCHA = 'VERIFY_CAPTCHA'