import {
  SIGNUP_USER,
  SIGNUP_USER_OTP_VERIFY,
  LOGIN_USER,
  LOGIN_USER_OTP_VERIFY,
  UPDATE_USER_PROFILE,
  RESET_USER,
  FETCH_USER_DATA,
  GET_CAPTCHA,
  VERIFY_CAPTCHA,
} from "./types";
import tileEcomapi from "../../apis";
import { setUserData, setUserToken } from "../../logics/User/userData";

export const signUpUser = (data) => (dispatch) => {
  tileEcomapi
    .post("user/signup", data)
    .then((signUpResp) => {
      if (signUpResp.data.statusCode == 201)
        dispatch({
          type: SIGNUP_USER,
          status: signUpResp.data.status,
          statusCode: signUpResp.data.statusCode,
          signUpResp: signUpResp.data.response,
        });
    })
    .catch((error) => {
      const { countryCode, phoneNumber } = data;
      if (error.response.data.statusCode == 409) {
        let loginObject = {
          countryCode: countryCode,
          phoneNumber: phoneNumber,
        };
        dispatch(loginUser(loginObject));
      }
    });
};

export const signUpOtpVerify = (data) => (dispatch) => {
  tileEcomapi
    .post("user/verify", data)
    .then((resp) => {
      dispatch({
        type: SIGNUP_USER_OTP_VERIFY,
        otpResp: resp.data,
      });
      setUserToken(resp.data.response.accessToken);
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_USER,
        payload: error.response.data,
      });
    });
};

export const loginUser = (data) => (dispatch) => {
  tileEcomapi
    .post("user/login", data)
    .then((loginResp) => {
      dispatch({
        type: LOGIN_USER,
        payload: loginResp,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_USER,
        payload: error.response.data,
      });
    });
};

export const loginOtpVerify = (data) => async (dispatch) => {
  const otpResp = await tileEcomapi.post("user/verify-login", data);
  dispatch({
    type: LOGIN_USER_OTP_VERIFY,
    otpResp: otpResp.data,
  });
  setUserToken(otpResp.data.response.accessToken);
};

export const updateProfile = (data) => (dispatch) => {
  tileEcomapi
    .post("user", data)
    .then((resp) => {
      if (resp) {
        dispatch(getUserData());
      }
    })
    .catch((error) => {});
};

export const updatePhoto = (data) => (dispatch) => {
  tileEcomapi
    .post("user/upload-profile-picture", data)
    .then((resp) => {
      if (resp) {
        dispatch(getUserData());
      }
    })
    .catch((error) => {});
};

export const getUserData = () => (dispatch) => {
  tileEcomapi
    .get("user")
    .then((resp) => {
      dispatch({
        type: FETCH_USER_DATA,
        payload: resp.data.response,
      });
      setUserData(resp.data.response);
    })
    .catch((error) => {
      dispatch({
        type: FETCH_USER_DATA,
        payload: error.response.data,
      });
    });
};

export const logoffUser = () => (dispatch) => {
  tileEcomapi
    .get("user/logout")
    .then((resp) => {
      dispatch({
        type: RESET_USER,
      });
      localStorage.clear();
    })
    .catch((error) => {});
};

export const getCaptcha = () => (dispatch) => {
  tileEcomapi
    .get("captcha/generate?width=150&height=70")
    .then((resp) => {
      dispatch({
        type: GET_CAPTCHA,
        payload: resp.data.response,
      });
    })
    .catch((error) => {});
};

export const verifyCaptcha = (data) => (dispatch) => {
  const { id, value } = data;
  const body={
    "value":value
  }
  tileEcomapi
    .post(`captcha/verify/${id}`, body)
    .then((resp) => {
      dispatch({
        type: VERIFY_CAPTCHA,
        payload: resp.data? 'true': 'false',
      });
    })
    .catch((error) => {
      dispatch({
        type: VERIFY_CAPTCHA,
        payload: 'false',
      });
    });
};

export const resetUser = () => async (dispatch) => {
  dispatch({
    type: RESET_USER,
  });
};
