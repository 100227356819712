import React,{useEffect, useState,useCallback} from "react";
import BreadcrumbsIcon from "../../../assets/icons/breadcrumbs-icon.svg"
const HeaderTab = ({tabs,setCheckoutTab,checkoutTab,activeTab,setActiveTab}) =>{
    
    const selectActiveItem = ((item) =>{
        
        setCheckoutTab(item)
        setActiveTab(item)
      })
    return(
        <div className="breadcrumbs align-items-start">
            <img className="icon" src={BreadcrumbsIcon} alt="" />
            { tabs && tabs.length && tabs.length>0 ?
            tabs.map((item,index) =>(
                <a key={index} className={`${activeTab ==item ? `link active`: `link`}`} onClick={()=>selectActiveItem(item)}>{item}</a>
            )

            ):null}
            {/* <a className="link">Categories</a>
            <a className="link">Floor Tiles</a>
            <a className="link">Living Room</a> */}
            {tabs[0] == "Wishlist" ?<span className="count"></span>:null}
        </div>
    )
}
export default HeaderTab