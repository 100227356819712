import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from "react";
import { updatePhoto } from '../../redux/actions/login';


const UserImage = ({ profileIcon, cameraIcon,profilePicture }) => {
    const [UserPhoto, setLoginData] = useState({})
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setLoginData({ file: e.target.files[0] })
    }

    useEffect(() => {
        if (UserPhoto.file) {
            var data = new FormData();
            data.append("file", UserPhoto.file);
            dispatch(updatePhoto(data))
        }
    }, [UserPhoto]);

    const onClick = () => {
        document.getElementById("selectImage").click()
    }

    return (
        <div className="user-image">
            <img className="image" src={profilePicture||profileIcon} alt="" />
            <a onClick={onClick}>
                <input type="file" id='selectImage' accept="image/*" hidden onChange={handleChange} />
                <img className="icon" src={cameraIcon} alt="" />
            </a>
        </div>

    )
}
export default UserImage