const ShareDetails = () =>{
        return(
            <div className="share-wrap">
                        <div className="share-text">
                            <div>Want to share this Quote?</div>
                            <div>Share it with your friends and family</div>
                        </div>
                        <div className="share-btn btn">
                            <div className="text">SHARE</div>
                            <img src="assets/icons/share-icon.svg" alt="" className="img" />
                        </div>
                    </div>
        )
}
export default ShareDetails