import { combineReducers } from 'redux';
import HomeReducer from './home'
import LoginReducer from "./login"
import productListReducer from "./productlist"
import productReducer from "./product";
import wishlistReducer from "./wishlist"

export default combineReducers({
    home:HomeReducer,
    login:LoginReducer,
    products:productListReducer,
    product:productReducer,
    wishlist:wishlistReducer
}); 