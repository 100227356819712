import React, { useState } from "react";
import { Link } from 'react-router-dom';
import plusIcon from '../../../../assets/icons/plus-icon.svg'
import AddressCard from "../../../MyAddress/AddressCard";

const Delivery = ({ addresses,setSelfPickup,isSelfPickup,handleStoreDefaultStore,defaultAddress }) => {
   
    return (
        <>
                <div className="delivery-details">
                    <div className="delivery-option">
                        <label id="pick" className="option-wrap">Self Pick Up
                            <input type="radio" name="delivery" value="1" onChange={(e)=> setSelfPickup(e.target.value)} checked={isSelfPickup=="1"}/>
                            <div className="custom-radio"></div>
                        </label>
                        <label className="option-wrap">Need Delivery
                            <input type="radio" name="delivery" value="0" onChange={(e)=> setSelfPickup(e.target.value)} checked={isSelfPickup=="0"} />
                            <div className="custom-radio"></div>
                        </label>
                    </div>
                    <div className="delivery-address">
                            <Link className="btn add-address" to='my-addresses' >
                                <img src={plusIcon} alt="" />
                                <div className="btn-text">ADD NEW ADDRESS</div>
                            </Link>
                        {addresses && addresses.length > 0 && addresses.map((item) => (
                            <AddressCard showIcon={false} address={item} handleStoreDefaultStore={handleStoreDefaultStore} defaultAddress={defaultAddress} />
                        )
                        )}

                    </div>
                    <hr />
                </div>
        </>
    )
}
export default Delivery;
