import React from "react";
import { useHome } from '../../logics/Home/useHome'
import { useHistory } from "react-router-dom";

const HomeTopBanner = ({ bannerList }) => {
  const history = useHistory();
  const banners = bannerList
  const logicProps = useHome()
  const { slideRight, slideLeft } = logicProps

  const navigateTo = (banner) => {
    let path = `/product-listing/+${banner.categoryId}/+${banner.subcategoryId}`
    history.push(path);
  }
  return (
    <div class="col offer-container mt-3 pb-3">
      <div class="viewport">
        {(banners?.length) &&
          banners.map((banner) => {
            return (
              <a class="offer" key={banner.id} onClick={()=>navigateTo(banner)}>
                <img src={banner.bannerImage} />
              </a>
            )
          })
        }
      </div>
      <button class="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
      <button class="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button>
    </div>

  )
}
export default HomeTopBanner;
