import React, { useEffect } from "react";
import StyleElements from "./StyleElements"

const CombineProducts = ({data}) => {
  const  product  = data;
  return(
    <div className="styles-wrap">
    <div className="title">Available Styles</div>
    <div className="d-flex">
       <StyleElements data={product}/>
    </div>
</div>
  )
}
export default CombineProducts