import React, { useRef, useState } from "react";

import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom';
import { useHeader } from "../../logics/Header/useHeader"
import ProfileDrawer from "../../components/ProfileDrawer"
import EditProfile from "../../components/ProfileDrawer/EditProfile";
import myAccountIcon from '../../assets/icons/my-account-icon.svg'
import orderUploadIcon from '../../assets/icons/order-upload-icon.svg'
import orderHistoryIcon from '../../assets/icons/order-history-icon.svg'
import { uploadOrder } from "../../redux/actions/home";
import Loader from '../../components/spinner';
import Auth from "../../pages/Auth";
import { resetUser } from "../../redux/actions/login";
import { isEmpty } from "lodash";


const ProfileSection = ({ userData }) => {
    const logicProps = useHeader();
    const dispatch = useDispatch();
    const { fileUploadStatus } = useSelector((state) => state.home);
    const [modalShow, setModalShow] = useState(false);


    const fileInput = useRef(null);

    const {
        openDrawer,
        EditProfileView,
        editView,
        openSigninModal,
        showDrawer
    } = logicProps;

    const fileUploadAction = () => {
        if (isEmpty(userData)) {
            setModalShow(true)
            return;
        }

        fileInput.current.click();
    };

    const onFilePicked = (event) => {
        event.preventDefault()
        const file = event.target.files[0];
        dispatch(uploadOrder(file))
    };

    const closeSigninModal = () => {
        dispatch(resetUser());
        setModalShow(false)
    }


    return (
        <div class="col welcome-card mx-0 px-0">
            <div class="welcome-card-container">
                <div class="row welcome-card-text">
                    <span>Hello {userData?.name || 'Guest'}!</span>
                    <span>Welcome back</span>
                </div>
                <div class="row welcome-card-buttons mt-5">
                    <a class="button" onClick={openDrawer}>
                        <img class="button-icon" src={myAccountIcon} alt="My Account" />
                        <span class="button-text">My Account</span>
                    </a>
                    <a class="button" onClick={fileUploadAction}>
                        <input hidden id="file" type="file" ref={fileInput} onChange={(e) => onFilePicked(e)} alt="Order History" accept="image/png, image/gif, image/jpeg" />
                        <img class="button-icon" src={orderUploadIcon} alt="Order Upload" />
                        <span class="button-text">Order Upload</span>
                        {fileUploadStatus && <Loader />}
                    </a>
                    {userData.userType == "3" && <Link to="/purchase-history" class="button">
                        <img class="button-icon" src={orderHistoryIcon} />
                        <span class="button-text">Purchase History</span>
                    </Link>}
                </div>
            </div>
            {showDrawer ? (editView ? <EditProfile backtoUserProfile={EditProfileView} saveProfileDetails={EditProfileView} /> : <ProfileDrawer closeProfileDrawer={openDrawer} EditProfileView={EditProfileView} openSigninModal={openSigninModal} />) : null}
            {modalShow && <Auth show={modalShow} closeSigninModal={closeSigninModal} />}

        </div>

    )
}
export default ProfileSection;
