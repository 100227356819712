import React from "react";
import { Link } from 'react-router-dom';

const ShippingPolicy = () => {
    return (
        <div className="content p-4 site-content">

            <div class="card">
                <div class="card-body">
                    <p><strong>Shipping</strong></p>
                    <ol>
                        <li><strong>What are the delivery charges?</strong></li>
                        Delivery charge depends on slab based kilometres and amount. If the amount or kilo meter not meet the slab delivery charges affected.
                        <li><strong>Why does the delivery date not correspond to the delivery timeline of X-Y business days?</strong></li>
                        It is possible that the Seller or our courier partners have a holiday between theyou&rsquo;re your placed your order and the date of delivery, which is based on the timelines shown on the product page. In this case, we add a day to the estimated date. Some courier partners and Sellers do not work on Sundays and this is factored in to the delivery dates.
                        <li><strong>What is the estimated delivery time?</strong></li>
                        Sellers generally procure and ship the items within the time specified on the product page. Business days exclude public holidays and Sundays.
                        Estimated delivery time depends on the following factors:
                        The Seller offering the product
                        Product&apos;s availability with the Seller
                        The destination to which you want the order shipped to and location of the Seller.
                        <li><strong>Are there any hidden costs (sales tax, octroi etc) on items sold by Sellers on Mywarehouse?</strong></li>
                        There are NO hidden charges when you make a purchase on Mywarehouse. List prices are final and all-inclusive. The price you see on the product page is exactly what you would pay.
                        Delivery charges are not hidden charges and are charged (if at all) extra depending on the Seller&apos;s shipping policy.
                        Why does the estimated delivery time vary for each seller?
                        You have probably noticed varying estimated delivery times for sellers of the product you are interested in. Delivery times are influenced by product availability, geographic location of the Seller, your shipping destination and the courier partner&apos;s time-to-deliver in your location.
                        Please enter your default pin code and location on the address page (you don&apos;t have to enter it every single time) to know more accurate delivery times on the product page itself.
                        <li><strong>Seller does not/cannot ship to my area. Why?</strong></li>
                        Please enter your pincode on the product page (you don&apos;t have to enter it every single time) to know whether the product can be delivered to your location.
                        If you haven&apos;t provided your pincode until the checkout stage, the pincode in your shipping address will be used to check for serviceability.
                        Whether your location can be serviced or not depends on
                        Whether the Seller ships to your location
                        Legal restrictions, if any, in shipping particular products to your location
                        The availability of reliable courier partners in your location
                        At times Sellers prefer not to ship to certain locations. This is entirely at their discretion.
                        <li><strong>Why CoD option not available?&nbsp;</strong></li>
                        Our ticket size and products quantities are high so that we are not giving CoD options.
                        <li><strong>I need to return an item, how do I arrange for a pick-up?</strong></li>
                        <span size="2">We don&rsquo;t have any pickup option .you have to return the products by yourself to our warehouse. Some products are not returnable. So you can see that particular products terms and conditions in product page.&nbsp;</span>
                        <li><strong>What do the different tags like &quot;In Stock&quot;, &quot;Available&quot; mean?</strong></li>
                        <p><strong>&apos;In Stock&apos;</strong></p>
                        For items listed as &quot;In Stock&quot;, Sellers will mention the delivery time based on your location pincode (usually 3-5 business days, 5-7 business days or 7-15 business days in areas where standard courier service is available). For other areas, orders will be sent by Registered Post through the Indian Postal Service which may take 2-4 weeks depending on the location.
                        <p><strong>&apos;Available&apos;</strong></p>
                        The Seller might not have the item in stock but can procure it when an order is placed for the item. The delivery time will depend on the estimated procurement time and the estimated shipping time to your location.
                        <p><strong>&apos;Preorder&apos;&nbsp;</strong></p>
                        Such items are in transit can be prebooked for you. The Preorder duration varies from item to item.
                        <p><strong>&apos;Out of Stock&apos;</strong></p>
                        Currently, the item is not available for sale. Use the &apos;Notify Me&apos; feature to know once it is available for purchase.
                        <p><strong>&apos;Imported&apos;</strong></p>
                        Sometimes, items have to be sourced by Sellers from outside India. These items are mentioned as &apos;Imported&apos; on the product page and can take at least 10 days or more to be delivered to you.
                        <p><strong>&apos;Back In Stock Soon&apos;</strong></p>
                        The item is popular and is sold out. You can however &apos;book&apos; an order for the product and it will be shipped according to the timelines mentioned by the Seller.
                        <p><strong>&apos;Temporarily Unavailable&apos;</strong></p>
                        The product is currently out of stock and is not available for purchase. The product could to be in stock soon. Use the &apos;Notify Me&apos; feature to know when it is available for purchase.
                        <p><strong>&apos;Permanently Discontinued&apos;</strong></p>
                        This product is no longer available because it is obsolete and/or its production has been discontinued.
                        <li><strong>Does Mywarehous deliver internationally?</strong></li>
                        As of now, Mywarehouse doesn&apos;t deliver items internationally.
                        You will be able to make your purchases on our site from anywhere in the world with credit/debit cards issued in India and 21 other countries, but please ensure the delivery address is in India.
                    </ol>
                </div>
                <div className="text-center p-2">
                    <Link to="/" class="btn btn-primary">OK</Link>
                </div>
            </div>
        </div>
    );
}

export default ShippingPolicy;