import React, { useCallback } from "react";
import deleteIcon from '../../../../assets/icons/bin-icon.svg';
import editIcon from '../../../../assets/icons/edit-icon-red.svg';
import { Link } from 'react-router-dom';
import { deleteCart } from "../../../../redux/actions/product";
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";

const Basket = ({ cartList = {} }) => {
    const { items, totalPrice } = cartList;
    const dispatch = useDispatch()
    const location = useLocation();

    const deleteCartItem = (productId) => {
        const data = [{
            productId: productId.toString(), units: 0
        }]
        dispatch(deleteCart(data, location))
    }
    let uniqueCategoryList = []
    uniqueCategoryList = items?.length && items.map(item => item.categoryName)
        .filter((value, index, self) => self.indexOf(value) === index)
    return (
        <>

            <div className="my-basket">

                {uniqueCategoryList?.length &&
                    (
                        uniqueCategoryList.map((item) => {
                            return (


                                <div className="category">
                                    <div className="category-title">{item}</div>
                                    <div className="cart-items-wrap">

                                        {items?.length && items.map((product) => (
                                            product.categoryName == item &&
                                            (<div className="card cart-item">
                                                <div className="item-image">
                                                    <img src={product?.displayImage} alt="" />
                                                </div>
                                                <div className="item-body">
                                                    <div className="item-name">{product.name}</div>
                                                    <div className="item-quantity">{product.price} x {product.units} = {product.price * product.units}</div>
                                                    <div className="item-spec">
                                                        Units: <strong>{product.units} Pack</strong> <strong>|</strong> Size: <strong>{product.size}</strong>
                                                    </div>
                                                    <div className="item-spec">
                                                        <span className="text-success">Estimated delivery in {product.inStock ? product.deliveryPeriod?.inStock : product.deliveryPeriod?.outOfStock} days</span>
                                                    </div>
                                                </div>
                                                <div className="item-btns">
                                                    <a onClick={() => deleteCartItem(product.productId)}><img src={deleteIcon} alt="" /></a>
                                                    <Link to={`/product-detail/${product.productId}`}><img src={editIcon} alt="" /></Link>
                                                </div>
                                            </div>)


                                        ))
                                        }

                                    </div>
                                </div>


                            )
                        })
                    )}

            </div>

        </>
    )
}
export default Basket;
