import { notification } from "antd";
import {
  FETCH_BANNER,
  FETCH_CATEGORIES,
  FETCH_POPULAR_CATEGORIES,
  FETCH_SUB_CATEGORIES,
  SELECT_CATEGORY_ID,
  FETCH_HOTLOOKS,
  FETCH_HOME_TOP_BANNER,
  FETCH_HOME_BANNER,
  FETCH_OFFER_BANNER,
  FETCH_OFFERS,
  FETCH_NON_TILE_OFFERS,
  FETCH_TILE_OFFERS,
  FETCH_NOTIFICATIONS,
  FETCH_ADDRESS,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  FETCH_START_POINT,
  GET_DISTANCE,
  CALCULATE_SHIPPING_CHARGE,
  UPLOAD_ORDER,
  FETCH_POPULAR_PRODUCTS,
  FETCH_NEW_ARRIVAL_PRODUCTS,
  FETCH_PRODUCT_BANNER,
  FETCH_BOTTOM_BANNER
} from "../actions/types.js";

const INTIAL_STATE = {
  status: "",
  productBannerData: [],
  bottomBannerData: [],
  homeTopBannerData: [],
  homeBannerData: [],
  offerTopBannerData: [],
  offers: [],
  nonTileOffers:[],
  tileOffers : [],
  offerBannerData: [],
  categoriesData: [],
  popularCategoriesData:[],
  subCategoryData: [],
  hotLooksData: [],
  popularProductsData: [],
  newArrivalProductsData:[],
  categoryDetails: "",
  notifyList: [],
  addressList: [],
  addressStatus:false,
  fileUploadStatus:false,
  distanceDetails:0
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_BANNER:
      return {
        ...state,
        productBannerData: action.payload,
      };
    case FETCH_BOTTOM_BANNER:
      return {
        ...state,
        bottomBannerData: action.payload,
      };
    case FETCH_HOME_TOP_BANNER:
      return {
        ...state,
        homeTopBannerData: action.payload,
      };
    case FETCH_HOME_BANNER:
      return {
        ...state,
        homeBannerData: action.payload,
      };
    case FETCH_OFFER_BANNER:
      return {
        ...state,
        offerTopBannerData: action.payload,
      };

    case FETCH_OFFERS:
      return {
        ...state,
        offers: action.payload,
      };
    case FETCH_NON_TILE_OFFERS:
      return {
          ...state,
          nonTileOffers: action.payload,
      };
    case FETCH_TILE_OFFERS:
      return {
            ...state,
            tileOffers: action.payload,
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        categoriesData: action.payload,
      };
    case FETCH_POPULAR_CATEGORIES:
      return {
        ...state,
        popularCategoriesData: action.payload,
      };
    case FETCH_SUB_CATEGORIES:
      return {
        ...state,
        subCategoryData: action.payload,
      };
    case SELECT_CATEGORY_ID:
      return {
        ...state,
        categoryDetails: action.payload,
      };
    case FETCH_HOTLOOKS:
      return {
        ...state,
        hotLookNextPageToken: action.payload.nextPageToken,
        hotLooksData: action.payload.products,
      };
    case FETCH_POPULAR_PRODUCTS:
        return {
          ...state,
          popularProductsData: action.payload.products,
        };
    case FETCH_NEW_ARRIVAL_PRODUCTS:
          return {
            ...state,
          newArrivalProductsData: action.payload.products,
       };
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifyList: action.payload,
      };
    case FETCH_ADDRESS:
      return {
        ...state,
        addressList: action.payload,
      };
    case ADD_ADDRESS:
      return {
        ...state,
        addressStatus: action.status,
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        status: action.status,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        addressStatus: action.status,
      };
     case UPDATE_ADDRESS:
      return {
        ...state,
        addressStatus: action.status,
      };
      case FETCH_START_POINT:
        
      return {
        ...state,
        startPoint: action.payload,
      };
      case GET_DISTANCE:
        
        return {
          ...state,
          distanceDetails: action.payload,
        };
        case CALCULATE_SHIPPING_CHARGE:
          return {
            ...state,
            shippingCharges: action.payload,
          };
          case UPLOAD_ORDER:
            return {
              ...state,
              fileUploadStatus: action.status,
            };
    default:
      return state;
  }
};
