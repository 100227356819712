import { razorpayOrder } from "../actions/product.js";
import {GET_PRODUCT_BY_ID,ADD_TO_CART,GET_CART,DELETE_CART,INSERT_TO_CART,GET_CART_COUNT,GET_MY_ORDERS,REMOVE_CART_COUNT,INITIATE_ORDER,RAZORPAY_ORDER} from "../actions/types.js";

const INTIAL_STATE = {
  loading:true,
  success:false,
  product:{},
  cartList:{},
  productQtyList:[],
  addToCartstatus:false,
  cartCount:false,
  orderList:{},
  orderDetails:{},
  orderPlaced:false,
  razorpayOrderStatus:''

};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        loading:false,
        product:action.payload,
        success:action.status
      };

      case GET_CART:
      return {
        ...state,
        loading:false,
        cartList:action.payload,
        success:action.status
      };
      case ADD_TO_CART:
      return {
        ...state,
        loading:false,
        addToCartstatus:action.status
      };
      case DELETE_CART:
      return {
        ...state,
        loading:false,
        success:action.status,
        addToCartstatus:action.status
      };

      case INSERT_TO_CART:
      return {
        ...state,
        loading:false,
        productQtyList:action.payload
      };

      case GET_CART_COUNT:
        return {
          ...state,
          cartCount:action.payload
        };
      
        case REMOVE_CART_COUNT:
        return {
          ...state,
          cartCount:false
        };


        case GET_MY_ORDERS:
          return {
            ...state,
            orderList:action.payload
          };

          case INITIATE_ORDER:
          return {
            ...state,
            orderDetails:action.payload,
            orderPlaced:action.status
          };
          case RAZORPAY_ORDER:
            return {
              ...state,
              razorpayOrderStatus:action.payload
            };
    default:
      return state;
  }
};
