import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import './NotificationDrawer.css'
import { useOutsideClickDetect } from "../../logics/OutsideClick/useOutsideClickDetect";
import { fetchNotifications } from "../../redux/actions/home";
import dummyProdcut from "../../assets/images/demo-product.jpeg";
import { useHistory } from "react-router-dom";


const NotificationDrawer = ({ closeNotificationDrawer }) => {
    const history = useHistory();

    const wrapperRef = useRef(null);
    useOutsideClickDetect(wrapperRef, closeNotificationDrawer);

    const { notifyList } = useSelector((state) => state.home);
    const dispatch = useDispatch()

    const navigateTo = (noti) => {
        let id = 0;
        if (noti?.entity_type == "product") {
            id = noti?.entity_id
        }
        else if (noti?.entity_type == "offer") {
            id = noti?.entity?.productIds[0]
        }

        let path = `/product-detail/${id}`
        history.push(path);
        closeNotificationDrawer();
    }

    useEffect(() => {
        dispatch(fetchNotifications())
    }, [])
    return (
        <div className="noti" >
            <a className="noti-close" onClick={closeNotificationDrawer}>✕</a>
            <div ref={wrapperRef}>
                <div className="noti-header">
                    <div className="noti-title">Notifications</div>
                </div>
                {notifyList?.length ?
                    (
                        notifyList.map((notify) => {
                            return (
                                <div className="noti-body" >
                                    <div className="noti-date">{notify.title}</div>
                                    <div className="card noti-card order-card">
                                        <div className="row">
                                            <img className="img-fluid" src={notify?.broadcast?.image_id || dummyProdcut} />

                                        </div>
                                        <div className="card-row btn-wrap text-right">
                                            <a onClick={() => navigateTo(notify)} className="btn accept-btn">
                                                <span>Buy now</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) :
                    <div className="noti-body text-primary text-center" >No notifications yet</div>
                }
            </div>
        </div>
    )
}
export default NotificationDrawer