import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import InvoiceDetails from "../../components/InvoiceDetails";
import PurchaseCard from "../../components/PurchaseCard";
import FilterBox from "./FilterBox";
import { useDispatch, useSelector } from 'react-redux'
import { getOrderList } from "../../redux/actions/product"

const MyOrders = () => {
    let breadCrumbTabs = ["My Orders"]
    const [selectedInvoiceData, setSelectedInvoiceData] = useState({})
    const { orderList } = useSelector((state) => state.product);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getOrderList(0, 2))
    }, [])
    useEffect(() => {
        if (orderList?.orders && orderList.orders.length > 0)
            setSelectedInvoiceData(orderList.orders[0])
    }, [orderList])
    const handleChangeInvoiceCard = (order) => {
        setSelectedInvoiceData(order)
    }

    const handleFilterOrder = (status) => {
        dispatch(getOrderList(0, 2, status))
    }
    return (
        <div className="purchase-history">
            <div className="left">
                <Breadcrumbs tabs={breadCrumbTabs} />
                <FilterBox handleFilterOrder={handleFilterOrder} />
                <div class="purchase-cards-wrap">

                    <PurchaseCard data={orderList?.orders} handleChangeInvoiceCard={handleChangeInvoiceCard} />
                </div>
            </div>
            {/* <div className="right"> 
                 <InvoiceDetails />
                 </div> */}
        </div>
    )
}
export default MyOrders