import React from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { selectCategoryId } from '../../redux/actions/home'
import { useHome } from '../../logics/Home/useHome'

const Grids = ({ CategotyList }) => {

    let history = useHistory();
    const dispatch = useDispatch()
    const logicProps = useHome()
    const { slideRight, slideLeft } = logicProps

    const handleClickCategory = (category) => {
        dispatch(selectCategoryId(category))
        history.push('./categories');
    }
    return (
        <div className="section-body">
            <div className="viewport">
                {CategotyList && CategotyList.length > 0 ? (
                    CategotyList.map((category) => {
                        return (
                            <a class="card card-2" key={category.id} onClick={() => handleClickCategory(category)}>
                                <img src={category.image} alt="" />
                                <div class="card-content">
                                    <span class="card-heading">{category.displayName}</span>
                                </div>
                            </a>
                        )
                    })) : null}
            </div>

            {CategotyList.length ?
                (<><button class="slideLeft" id="slideLeft" type="button" onClick={(e) => slideLeft(e)}></button>
                    <button class="slideRight" id="slideRight" type="button" onClick={(e) => slideRight(e)}></button></>) : null}
        </div>
    )
}
export default Grids;