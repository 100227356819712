import React from "react";
import { Link } from 'react-router-dom';


const Faq = () => {
    return (
        <div className="content p-4 site-content">

            <div class="card">
                <div class="card-body">
                    <p><strong>FAQ</strong></p>
                    <ul>
                        <li><strong>How to delete My-Warehouse account?</strong></li>
                        <div>Send an email to enquiry@mywarehouseonline.com</div>
                    </ul>
                </div>
                <div className="text-center p-2">
                    <Link to="/" class="btn btn-primary">OK</Link>
                </div>
            </div>
        </div>
    );
}

export default Faq;