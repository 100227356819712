import { GET_WISH_LIST,ADD_ITEM_TO_WISHLIST,REMOVE_ITEM_FROM_WISHLIST,REFRESH_WISHLIST_STATUS } from "../actions/types.js";

const INTIAL_STATE = {
    itemAddedToWishlist:false,
    itemRemovedFromWishlist:false,
    productList: {},
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case GET_WISH_LIST:
       
      return {
        ...state,
        wishList: action.payload
      };
      case ADD_ITEM_TO_WISHLIST:
        return {
          ...state,
          itemAddedToWishlist: action.payload
        };
        case REMOVE_ITEM_FROM_WISHLIST:
        return {
          ...state,
          itemRemovedFromWishlist: action.payload
        };
        case REFRESH_WISHLIST_STATUS:
        return {
          ...state,
          itemAddedToWishlist:false,
          itemRemovedFromWishlist: false
        };

    default:
      return state;
  }
};
