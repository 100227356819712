import React from "react";
import './ProductListing.css'
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const CardProduct = ({ cardProductDetail, handleChangeWishlist }) => {
    const location = useLocation();
    const history = useHistory();
    const handleChange = (e, cardProductDetail) => {
        e.stopPropagation();
        handleChangeWishlist(cardProductDetail);
    }

    const handleOnClick = (productId) => {
        history.push(`/product-detail/${productId}`)
    };

    const getImageUrl = () => {
        if (cardProductDetail?.productImages) {
            return cardProductDetail.productImages[0];
        }
        else if (cardProductDetail?.displayImage) {
            return cardProductDetail.displayImage[0];
        }
    };
    return (

        <div class="card product-card" onClick={() => handleOnClick(cardProductDetail?.productId)}>

            <div class="product-image">
                <img src={getImageUrl()} alt="" />
                <a onClick={(e) => handleChange(e, cardProductDetail)}>
                    <i className={`fa fa-heart fa-2x wish-icon  ${cardProductDetail?.isWishListed ? 'text-danger' : 'text-white'}`} aria-hidden="true"></i>
                </a>
            </div>
            <div class="product-details">
                <a class="product-name">{location.pathname == "/wishlist" ? cardProductDetail.name : cardProductDetail.title}</a>
                <div class="product-price">
                    <div class="offer">
                        <div class="old-price">₹ {cardProductDetail.mrp}</div>
                        <div class="off-percentage">{cardProductDetail.discount}% OFF</div>
                    </div>
                    <div class="price">₹ {cardProductDetail.price} Per Piece</div>
                    {cardProductDetail.isTileOrGranite ? (<div class="persqfeet">₹ {cardProductDetail.ratePerSquareFeet} Per Square feet </div>):null}
                </div>
            </div>

        </div>

    )


}
export default CardProduct




