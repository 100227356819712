import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useHome } from "../../logics/Home/useHome";
import { Link } from "react-router-dom";
import { addItemtoWishlist, removeItemFromWishlist, getWishlist, refreshWishlistStatus } from "../../redux/actions/wishlist"
import wishlistIcon from '../../assets/icons/wishlist-icon.svg'

const Grids = ({ hotLookList, tag }) => {
  const logicProps = useHome();
  const [projectSelected, setProjectSelected] = useState({});
  const { itemAddedToWishlist, itemRemovedFromWishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch()
  const history = useHistory();
  const { slideRight, slideLeft } = logicProps;
  //   let mrp = this?.mrp?.toDoubleOrNull() ?: 1.toDouble()
  //   let price = this?.price?.toDoubleOrNull() ?: 0.toDouble()
  //   let percentageOffer = (((mrp - price) / mrp) * 100.toDouble()).toString()
  useEffect(() => {

    if (itemAddedToWishlist || itemRemovedFromWishlist) {
      projectSelected.isWishListed ? projectSelected.isWishListed = 0 : projectSelected.isWishListed = 1
      dispatch(refreshWishlistStatus())
    }

  }, [tag,itemAddedToWishlist, itemRemovedFromWishlist])
  const handleOnClick = (productId) => {
    history.push(`/product-detail/${productId}`)
};

  const handleChangeWishlist = (e,product) => {
    e.stopPropagation();
    setProjectSelected(product)
    const body = {
      productIds: [product.productId]
    }
    if (product.isWishListed) {

      dispatch(removeItemFromWishlist(body))
    }
    else
      dispatch(addItemtoWishlist(body))
  }

  return (
    <div className="section-body shadowed">
      <div className="viewport">
        {hotLookList && hotLookList.length > 0
          ? hotLookList.map((product) => {
              return (
                <div
                onClick={() => handleOnClick(product?.productId)}
                  className="row tiny-card"
                >
                  <img src={product.productImages[0]} alt="" />
                  <a onClick={(e) => handleChangeWishlist(e, product)}>
                    <i className={`fa fa-heart fa-2x wish-icon  ${product?.isWishListed ? 'text-danger' : 'text-white'}`} aria-hidden="true"></i>
                  </a>
                  <div className="card-content">
                    <div className="card-heading mb-0">
                      <p>{product.title}</p>
                      {/* <p>{product.categoryName}</p> */}
                    </div>
                    <div className="row align-items-end">
                      <div className="price-wrap">
                        <span className="price-off">
                          <strike>₹ {product.mrp}</strike>&nbsp;
                          <span className="text-danger">
                            {Math.round(
                              ((product.mrp - product.price) / product.mrp) *
                                parseInt(100)
                            ).toString()}
                            % OFF
                          </span>
                        </span>
                        <p className="price">₹ {product.price} Per Piece</p>
                        {product.isTileOrGranite ? (<p className="sqfeet">₹ {product.ratePerSquareFeet} Per Square feet</p>) : null}
                      </div>
                    </div>
                    <div className={`tag ${tag.replace(/\s+/g, '-').toLowerCase()}`}>
                        <span>{tag}</span>
                    </div>
                    {/* <div className="wishlist-add">
                        <img src={wishlistIcon} alt="" />
                    </div> */}
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {hotLookList && hotLookList.length > 3 ? (
        <>
          <button
            className="slideLeft"
            id="slideLeft"
            type="button"
            onClick={(e) => slideLeft(e)}
          ></button>
          <button
            className="slideRight"
            id="slideRight"
            type="button"
            onClick={(e) => slideRight(e)}
          ></button>
        </>
      ) : null}
    </div>
  );
};
export default Grids;
